import { html, isServer, nothing } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './secondary-navigation-level-one.scss?inline';
import '../main-nav-item/main-nav-item';
import '../secondary-navigation-level-two/secondary-navigation-level-two';

/**
 * @summary This component generates the level one navigation within the secondary navigation, and generates a main-nav-item at desktop and either a collapsible or a link (depending on props) at mobile.
 *
 * @slot default One or more pds-secondary-navigation-level-two elements
 */
@customElement('pds-secondary-navigation-level-one', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsSecondaryNavigationLevelOne extends PdsElement {
  firstUpdated(): void {
    this.setWindowResizeHandler();
  }

  updated(changedProperties: Map<string, unknown>): void {
    if (
      changedProperties.has('activeSectionCallback') &&
      this.activeSectionCallback
    ) {
      this.activeSectionCallback();
    }
  }

  /**
   * The title of the top tier item (whether a parent or link)
   */
  @property({ type: String })
  title: string = '';

  /**
   * If the top tier item is a link, the href for it
   */
  @property({ reflect: true })
  href: string;

  /**
   * However many columns the items under a parent should have at desktop
   */
  @property()
  columns: '1col' | '2col' | '3col' | '4col' = '1col';

  /**
   * @internal
   * Set to true for visual representation of the user's current section
   * If not set, component logic will determine the value by matching the window location's href to the nested links' href properties
   */
  @property()
  activeSection: boolean = false;

  /**
   * @internal
   * This grabs the secondary-nav-level-one items
   */
  @queryAssignedElements({ slot: undefined })
  defaultSlotElements: HTMLElement[];

  /**
   * @internal
   * Function to determine if this is the active section
   * This function looks at the url of each secondary nav level two item
   * and determines if the current URL matches the nav item URL
   * This is the default behavior of the activeSectionCallback prop
   *
   */
  determineActiveSection(): void {
    // Check if this is a link and if it's the current URL
    // If so, set activeSection to true and bail early
    if (this.href && window.location.href === this.href) {
      this.activeSection = true;
      return;
    }

    this.defaultSlotElements.forEach((element: HTMLElement) => {
      if (element.tagName === 'PDS-SECONDARY-NAVIGATION-LEVEL-TWO') {
        // @ts-expect-error __href is a custom attribute
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const elementHref = element.getAttribute('href') ?? element['__href'];
        if (window.location.href === elementHref) {
          this.activeSection = true;
        }
      }
    });
  }

  /**
   * Callback function to determine if this is the active section
   * Defaults to looking at the url of each secondary nav level two item
   * and determines if the current URL matches the nav item URL
   */
  @property({ type: Function })
  activeSectionCallback?: Function = this.determineActiveSection;

  handleSlotChange() {
    this.requestUpdate();
  }

  render() {
    if (
      !isServer &&
      window &&
      window.visualViewport &&
      (this.responsiveViewportSize === 'xs' ||
        this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md') &&
      this.href
    ) {
      return html`<div class="${this.getClass()}">
        <div class="${this.classMod('mobile')}">
          <pds-link
            class="${this.classEl('menu-item-section')}"
            href="${this.href}"
            ariaLabel="${this.title}"
            ariaCurrent="${this.activeSection ? 'true' : nothing}"
            >${this.title}</pds-link
          >
        </div>
      </div>`;
    }
    if (
      !isServer &&
      window &&
      window.visualViewport &&
      (this.responsiveViewportSize === 'xs' ||
        this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md')
    ) {
      return html`<div class="${this.getClass()}">
        <div class="${this.classMod('mobile')}">
          <pds-collapsible
            variant="secondary-nav"
            class="${this.classEl('menu-item-section')}"
            ><span slot="summary-title">${this.title}</span>
            <span slot="collapsible-content">
              <slot
                @slotchange=${this.handleSlotChange}
                class="${this.classEl('slot')}"
              ></slot></span
          ></pds-collapsible>
        </div>
      </div>`;
    }
    if (this.href) {
      return html`<div class="${this.getClass()}">
        <div class="${this.classMod('desktop')}">
          <pds-main-nav-item
            activeSection="${this.activeSection || nothing}"
            class="${this.classEl('menu-item')}"
            href="${this.href}"
            text="${this.title}"
          ></pds-main-nav-item>
        </div>
      </div>`;
    }
    return html`<div class="${this.getClass()}">
      <div class="${this.classMod('desktop')}">
        <pds-main-nav-item
          dropdown
          activeSection="${this.activeSection || nothing}"
          class="${this.classEl('menu-item')}"
          text="${this.title}"
        >
          <span class="${this.classMod(this.columns)}">
            <slot
              @slotchange=${this.handleSlotChange}
              class="${this.classEl('slot')}"
            ></slot></span
        ></pds-main-nav-item>
      </div>
    </div>`;
  }
}
