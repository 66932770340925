import '@principal/design-system-icons-web/chevron-down';
import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { property, query } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormElement } from '../pds-form-element/PdsFormElement';
import styles from './select.scss?inline';

/**
 * @summary This component renders a styled select input component.
 *
 * @slot label Use this slot instead of the label property, if the label requires additional markup.
 * @slot label-after Use this slot if markup should be inserted after the label.
 * @slot help-text Use this slot instead of the helpText property, if the help text requires additonal markup.
 */
@customElement('pds-select', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsSelect extends PdsFormElement {
  /**
   * Size
   * -**sm** renders a the small version of the Select
   */
  @property()
  size: 'sm' | 'default' = 'default';

  @property({ type: String })
  placeholder?: string;

  @query('select')
  field: HTMLSelectElement;

  /**
   * Set the selected option based on the current value
   */
  protected override updateField() {
    // for loop for the case where a browser doesn't make a collection iterable
    for (let i = 0; i < this.field?.options?.length; i += 1) {
      const option = this.field.options[i];

      if (option.value === (this.internalValue || '')) {
        option.selected = true;
        break;
      }
    }
  }

  protected override firstUpdated() {
    super.firstUpdated();

    this.updateField();
  }

  private handleBlur() {
    this.dispatchEvent(
      new CustomEvent('pds-select-blur', {
        bubbles: false,
        composed: true,
        detail: {
          summary: this.value,
        },
      }),
    );
  }

  private handleChange() {
    this.value = this.field.value;
    this.dispatchEvent(
      new CustomEvent('pds-select-change', {
        bubbles: true,
        composed: true,
        detail: {
          summary: this.value,
        },
      }),
    );
  }

  private handleFocus() {
    this.dispatchEvent(
      new Event('pds-select-focus', { bubbles: false, composed: true }),
    );
  }

  /** @internal */
  get classNames() {
    return {
      [this.size]: !!this.size,
      'is-error': !!this.errorMessage,
      'is-disabled': this.disabled,
      'hidden-label': this.hideLabel,
    };
  }

  render() {
    if (!this.verifyLabel()) {
      return nothing;
    }

    return html`<div class="${this.getClass()}">
      ${this.labelTemplate()} ${this.helpTextTemplate()}
      <div class="${this.classEl('wrapper')}">
        <select
          class="${this.classEl('select')}"
          id="${this.fieldId || `${this.name}-field`}"
          ?disabled=${this.disabled}
          ?required=${this.required}
          aria-describedby=${this.getAriaDescribedBy() || nothing}
          aria-invalid=${this.errorMessage ? 'true' : nothing}
          name="${this.name}"
          @change=${this.handleChange}
          @focus=${this.handleFocus}
          @blur=${this.handleBlur}
        >
          <option
            id="placeholder"
            value=""
            ?hidden=${this.required}
            ?disabled=${this.required}
          >
            ${this.placeholder}
          </option>
          ${Array.from(this.children).map((child: Element) => {
            return html`${unsafeHTML(child.outerHTML)}`;
          })}
        </select>
        <span class="${this.classEl('down')}">
          <pds-icon-chevron-down size="default"></pds-icon-chevron-down>
        </span>
      </div>
      ${this.errorMessageTemplate()}
    </div>`;
  }
}
