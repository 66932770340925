import * as React from 'react';
import { PdsMainNav as PdsMainNavElement } from '@principal/design-system';
import { PdsMainNavProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsMainNav = createPdsReactComponent<PdsMainNavProps>()({
  tagName: 'pds-main-nav',
  elementClass: PdsMainNavElement,
  react: React,
});
