import { PdsButton } from "@principal/design-system-react";
import { findValidationErrors } from "../utilities/Validation";
import { useAtom } from "jotai";
import { pdfs, xmls } from "../atoms/Atoms";
import { FileData } from "../interfaces/FileData";
import { BinaryFileData } from "../interfaces/BinaryFileData";

export function ValidateFilesButton(props: any) {

    const [pdfList,] = useAtom(pdfs);
    const [xmlFile,] = useAtom(xmls);
    const pdfFiles = pdfList as [BinaryFileData];
    const xml = xmlFile as [FileData];
    return (
        <div>
            <PdsButton
                variant="primary"
                disabled={props.disabled}
                onClick={() => {
                    if (findValidationErrors(xml, pdfFiles, props.errorMessageChanger).length === 0 ) {
                        props.validationChanger(true);
                    }
                }}>
                VALIDATE FILES
            </PdsButton>
        </div>
    )
}
