import * as React from 'react';
import { PdsDescriptionList as PdsDescriptionListElement } from '@principal/design-system';
import { PdsDescriptionListProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsDescriptionList =
  createPdsReactComponent<PdsDescriptionListProps>()({
    tagName: 'pds-description-list',
    elementClass: PdsDescriptionListElement,
    react: React,
  });
