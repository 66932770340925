import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import { required } from '../../decorators/required';
import styles from './utility-nav-item.scss?inline';

/**
 * @summary A list item (li) element that contains a link (a)
 *
 * @slot default The link text
 *
 * @fires utility-nav-item-click A custom event dispatched on click
 */
@customElement('pds-utility-nav-item', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsUtilityNavItem extends PdsElement {
  /**
   * The link for the item
   */
  @required
  @property()
  href: string;

  handleClick() {
    const event = new CustomEvent('pds-utility-nav-item-click', {
      bubbles: true,
      composed: true,
      detail: {
        summary: this.textContent,
      },
    });

    this.dispatchEvent(event);
  }

  render() {
    return html`
      <li class=${this.getClass()} role="listitem">
        <a
          @click=${this.handleClick}
          class="pds-c-utility-nav-item__link"
          href=${this.href}
          aria-label="link"
        >
          <slot class="pds-c-utility-nav-item__text"></slot>
        </a>
      </li>
    `;
  }
}
