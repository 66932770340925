import { PdsButton } from "@principal/design-system-react";
import { useAtom } from "jotai";
import { pdfs, xmls } from "../atoms/Atoms";
import { FileData } from "../interfaces/FileData";
import { parseString } from "xml2js";
import { extractFileName } from "../utilities/FileNameExtractor";
import { getAccessToken } from "../utilities/Authentication";
import { v4 as uuidv4 } from "uuid";
import DateFormatMasks from "dateformat";
import { BinaryFileData } from "../interfaces/BinaryFileData";
import Modal from 'react-bootstrap/Modal'
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

const batchID: string = "batch-" + DateFormatMasks(Date.now(), "yyyy-mm-dd-HH-MM-ss") + "-" + uuidv4();

export function SubmitFilesButton(props: any) {

    const [pdfList,] = useAtom(pdfs);
    const [xmlFile,] = useAtom(xmls);
    const pdfFiles = pdfList as [BinaryFileData];
    const xml = xmlFile as [FileData];
    const [progress, setProgress] = useState(1);    
    const [modalShow, setModalShow] = useState(false);
    const { accounts, instance } = useMsal();
    const divStyle = { color: "grey" };
    
    function handleClose(){
        props.submittedChanger(true)
        setModalShow(false);
        window.location.reload();
    }

    return (
        <div>
            <PdsButton
             variant="primary"
                    onClick={async () => {
                    setModalShow(true)
                    await performUploadFiles(batchID, xml, pdfFiles, setProgress, accounts, instance)
                }}
            >
                SUBMIT
            </PdsButton>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop = "static"
                show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>File Upload Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><h5>Your files are currently being uploaded.</h5></p>
                    <p><h5><b><mark>Do not close this screen until progress has reached 100%. </mark></b></h5></p>
                    <ProgressBar now={progress} label={`${progress}%`} />
                    <br /><br />
                    <p><h5>Please check your email inbox upon completion for processing results .</h5>
                       <span style={divStyle}>{batchID}</span>
                    </p>
                    <p><a href="https://forms.office.com/r/gPanfWQM36" target="_blank" rel="noreferrer">Share your feedback.</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <PdsButton disabled={progress < 99} variant="default" onClick={handleClose}>
                        Close
                    </PdsButton>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export async function performUploadFiles(batchID: string, xmlFile: [FileData], pdfList: [BinaryFileData], setProgressNumber: Function, theAccounts: AccountInfo[], theInstance: IPublicClientApplication): Promise<boolean> {

    const applicationAPI = process.env.REACT_APP_API;

    const accessToken = await getAccessToken(theAccounts, theInstance);
    // for loop for each PDF in the list
    try {
        if (pdfList.length !== undefined) {
            let totalNumberOfFiles = pdfList.length;
            let numberProcessed = 0;
            let numberEvaluated = 0;
            let promiseResponseList: Promise<Response>[] = [];  

            for (let pdf of pdfList) {

                let fileName = extractFileName(pdf.fileName);
                let base64 = btoa(
                    new Uint8Array(pdf.fileData)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                
                let pdfUploadAPI = applicationAPI + 'batch/' + batchID + '/file/' + fileName;
                let apiRequest = {
                    method: 'POST',
                    headers: {'content-type': 'application/pdf; charset=UTF-8', 'accept': 'application/pdf', 'authorization': `Bearer ${accessToken}`},
                    body: base64
                };

                try {
                    if( /^([a-zA-Z0-9_-]+\.pdf)$/.test(fileName) === true ) {
                            let promiseResponse = fetch(pdfUploadAPI, apiRequest);
                            if((await promiseResponse) != null) {
                                let responseStatus = (await promiseResponse).status;
                                let responseStatusText = (await promiseResponse).statusText;
                                if(responseStatus >= 400){
                                    throw new Error(responseStatus + " error received while uploading to S3: " + responseStatusText);
                                }
                            }
                            promiseResponseList.push(promiseResponse);
                        }
                        let promiseCount = promiseResponseList.length;
                        let modValue: boolean = ((promiseCount % 5) === 0);
                        let endOfList: boolean = (numberEvaluated + 1) === totalNumberOfFiles;

                    if (modValue || endOfList) {
                        numberProcessed = await updateProgressValues(promiseResponseList, numberProcessed, totalNumberOfFiles, setProgressNumber);
    
                        promiseResponseList = [];
                    }
                } catch(error){
                    console.log("Error while posting pdf file: " + fileName);
                    if(error instanceof Error){
                        console.log("Fetch error:" + error.message);
                    }
                }               
                numberEvaluated++;
            }
        }

    } catch(error){
        console.log("Error occurred while processing pdf file");
        if(error instanceof Error){
            console.log("Error from processing pdf file:" + error.message);
        }
    }

    let xmlUploadAPI = applicationAPI + 'batch/' + batchID + '/metadata';

    try {
        await xmlFile.forEach(xml => {
            parseString(xml.fileData.toString(), async (err, result) => {   // Convert to JSON

            trimExtraSpacesfromXML(result["ns1:itemRegistryRequest"]["ns1:itemDetails"]);
                for (let i = 0; i < result["ns1:itemRegistryRequest"]["ns1:itemDetails"].length; i++) {

                    result["ns1:itemRegistryRequest"]["ns1:itemDetails"][i]["ns1:documentName"][0] =
                     encodeURIComponent(String(result["ns1:itemRegistryRequest"]["ns1:itemDetails"][i]["ns1:documentName"][0]).trim())
                    }
                
                let apiRequest = {
                    method: 'POST',
                    headers: {'authorization': `Bearer ${accessToken}`},
                    body: JSON.stringify(result)
                }
                
                try{
                    let promiseResponse = fetch(xmlUploadAPI, apiRequest);
                    if ((await promiseResponse) != null) {
                        let responseStatus = (await promiseResponse).status;
                        let responseStatusText = (await promiseResponse).statusText;
                        if(responseStatus >= 400) {
                            throw new Error(responseStatus + " error received while uploading to S3: " + responseStatusText);
                        }
                    }
                } catch(error){
                    console.log("Error occurred while posting the metadata file.");
                    if(error instanceof Error){
                        console.log("Fetch error: " + error.message);
                    }
                }
            })
        })

    } catch(error) {
        console.log('Error occurred while processing metadata file');
        if(error instanceof Error){
            console.log("Error from processing metadata file : " + error.message);
        }
    }
    setProgressNumber(100);  
    return true;
}



async function updateProgressValues(promiseResponseList: Promise<Response>[], numberProcessed: number, totalNumberOfFiles: number, setProgressNumber: Function) {
    await Promise.all(promiseResponseList).then(() => {
        numberProcessed += promiseResponseList.length;
        const progressNumber = ((numberProcessed / totalNumberOfFiles) * 100).toFixed(0);

        setProgressNumber(progressNumber);
    }).catch((reason) => {
        console.log('Error updating progress value: ' + reason.toString());
    })
    return numberProcessed;
}

export function trimExtraSpacesfromXML(requestListData: any) {

    // Check rules for each input in the request's data
    for (let i = 0; i < requestListData.length; i++) {

        requestListData[i]["ns1:action"][0] =  String(requestListData[i]["ns1:action"][0]).toUpperCase().trim();
        requestListData[i]["ns1:businessId"][0]["ns1:prefix"][0] = String(requestListData[i]["ns1:businessId"][0]["ns1:prefix"][0]).trim();
        requestListData[i]["ns1:businessId"][0]["ns1:baseNumber"][0] = String(requestListData[i]["ns1:businessId"][0]["ns1:baseNumber"][0]).trim();
        requestListData[i]["ns1:businessId"][0]["ns1:suffix"][0] = String(requestListData[i]["ns1:businessId"][0]["ns1:suffix"][0]).trim();
        if (requestListData[i]["ns1:businessId"][0]["ns1:revisionNumber"]) {
            requestListData[i]["ns1:businessId"][0]["ns1:revisionNumber"][0] = String(requestListData[i]["ns1:businessId"][0]["ns1:revisionNumber"][0]).trim();
        }
        if(requestListData[i]["ns1:description"]){
            requestListData[i]["ns1:description"][0] = String(requestListData[i]["ns1:description"][0]).trim();
        }
        if(requestListData[i]["ns1:informationClassification"]){
            requestListData[i]["ns1:informationClassification"][0] = String(requestListData[i]["ns1:informationClassification"][0]).trim();
        }
        if(requestListData[i]["ns1:retentionCode"]){
            requestListData[i]["ns1:retentionCode"][0] = String(requestListData[i]["ns1:retentionCode"][0]).trim();
        }
        if(requestListData[i]["ns1:title"]){
            requestListData[i]["ns1:title"][0] = String(requestListData[i]["ns1:title"][0]).trim();
        }
        if(requestListData[i]["ns1:contentOwner"]){
            requestListData[i]["ns1:contentOwner"][0] = String(requestListData[i]["ns1:contentOwner"][0]).trim();
        }
        if(requestListData[i]["ns1:versionEffectiveDate"]){
            requestListData[i]["ns1:versionEffectiveDate"][0] = String(requestListData[i]["ns1:versionEffectiveDate"][0]).trim();
        }    
        if(requestListData[i]["ns1:expirationDate"]){
            requestListData[i]["ns1:expirationDate"][0] = String(requestListData[i]["ns1:expirationDate"][0]).trim();
        }    
        if(requestListData[i]["ns1:reviewByDate"]){
            requestListData[i]["ns1:reviewByDate"][0] = String(requestListData[i]["ns1:reviewByDate"][0]).trim();
        }
        if(requestListData[i]["ns1:comments"]){
            requestListData[i]["ns1:comments"][0] = String(requestListData[i]["ns1:comments"][0]).trim();
        }
        if(requestListData[i]["ns1:itemType"]){
            requestListData[i]["ns1:itemType"][0] = String(requestListData[i]["ns1:itemType"][0]).trim();
        }
        if(requestListData[i]["ns1:itemSubType"]){
            requestListData[i]["ns1:itemSubType"][0] = String(requestListData[i]["ns1:itemSubType"][0]).trim();
        }
        if(requestListData[i]["ns1:documentAvailableViaSystems"]){
            requestListData[i]["ns1:documentAvailableViaSystems"][0] = String(requestListData[i]["ns1:documentAvailableViaSystems"][0]).trim();
        }
        if(requestListData[i]["ns1:publicationEffectiveDate"]){
            requestListData[i]["ns1:publicationEffectiveDate"][0] = String(requestListData[i]["ns1:publicationEffectiveDate"][0]).trim();
        }
        if(requestListData[i]["ns1:customerType"]){
            requestListData[i]["ns1:customerType"][0] = String(requestListData[i]["ns1:customerType"][0]).trim();
        }
        if(requestListData[i]["ns1:tickerSymbol"]){
            requestListData[i]["ns1:tickerSymbol"][0] = String(requestListData[i]["ns1:tickerSymbol"][0]).trim();
        }    

    }
}
