import { PropertyValueMap, html, nothing } from 'lit';
import { msg, localized } from '@lit/localize';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  property,
  query,
  state,
  queryAssignedElements,
} from 'lit/decorators.js';
import { createPopper } from '@popperjs/core';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './primary-navigation.scss?inline';
import '@principal/design-system-icons-web/menu';
import '@principal/design-system-icons-web/x';
import '@principal/design-system-icons-web/bell';
import '@principal/design-system-icons-web/bell-notification';
import '@principal/design-system-icons-web/search';
import '../nav-container/nav-container';
import '../button/button';
import '../link/link';
import '../layout-container/layout-container';
import '../list/list';
import '../list-item/list-item';
import '../logo/logo';
import '../main-nav/main-nav';
import '../main-nav-item/main-nav-item';
import '../utility-nav/utility-nav';
import '../utility-nav-item/utility-nav-item';
import '../nav-dropdown-link/nav-dropdown-link';
import { required } from '../../decorators/required';

/**
 * @summary A component that renders a primary navigation
 *
 * @slot logo A slot for an optional custom logo
 * @slot main-nav A main navigation component containing one or more main navigation items
 * @slot utility-nav A utility navigation component containing one or more utility navigation items
 * @slot search If passed in, will populate the search icon
 * @fires pds-primary-navigation-click A custom event dispatched on click
 */
@customElement('pds-primary-navigation', {
  category: 'component',
  type: 'component',
  styles,
})
// This is important to place below the @customElement decorator (https://github.com/lit/lit/issues/3264)
@localized()
export class PdsPrimaryNavigation extends PdsElement {
  /**
   * Style variant
   * - **default** renders the primary-navigation used for primary actions
   * - **inverted** renders the primary-navigation used for primary actions
   */
  @required
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * Determines if search | notifications should be rendered
   * - **search** renders the search icon & panel in the navigation
   * - **notification** renders the notification bell & panel in the navigation
   */
  @property()
  includeAction?: 'search' | 'notification';

  /**
   * Determines if we should place the header in a layout container
   * If this property isn't passed, no layout container is used.
   */
  @property({ type: String })
  layoutContainerVariant?: 'default' | 'narrow';

  /**
   * Remove layout container padding (only to be used if layoutContainerVariant has a value)
   * - **md** removes padding from the layout container below md breakpoint
   * - **all** removes padding from the layout container at all screens (used for nested layout containers)
   */
  @property({ type: String })
  removeLayoutContainerPadding?: 'md' | 'all';

  /**
   * Gives users some flexibility if they don't want other notifications to show in the notifications panel
   */
  @property({ type: Boolean })
  hideOtherAlerts: boolean = false;

  /**
   * The number of unread messages
   */
  @property({ type: Number })
  messagesCount: number = 0;

  /**
   * The number of other unread notifications
   */
  @property({ type: Number })
  otherAlertsCount: number = 0;

  /**
   * Link to the message center
   */
  @property({ type: String })
  messagesHref: string =
    'https://secure05.principal.com/servlet/common/member/messagecenter';

  // TODO: is there a notifications center default link?
  /**
   * Link to the notifications center
   */
  @property({ type: String })
  otherAlertsHref: string = 'https://www.principal.com';

  /**
   * The header element that contains the entire primary navigation
   * @internal
   */
  @query('header')
  header: HTMLElement;

  /**
   * The notifications panel card
   * @internal
   */
  @query('.pds-c-primary-navigation__notification-panel')
  notificationsPanel: HTMLElement;

  /**
   * The search panel card
   * @internal
   */
  @query('.pds-c-primary-navigation__search-panel')
  searchPanel: HTMLElement;

  /**
   * The notifications bell button (either with messages or without)
   * @internal
   */
  @query('.pds-c-primary-navigation__bell-icon')
  bellIcon: HTMLElement;

  /**
   * The search icon button
   * @internal
   */
  @query('.pds-c-primary-navigation__search-icon')
  searchIcon: HTMLElement;

  clickNotificationLink(linkId: string) {
    const notificationLink = this.shadowRoot?.querySelector(
      `#${linkId}`,
    ) as HTMLAnchorElement;
    const textContent = notificationLink?.textContent;

    const pdsCustomEvent = new CustomEvent(
      'pds-primary-navigation-notification-link-click',
      {
        bubbles: true,
        composed: true,
        detail: {
          summary: textContent,
        },
      },
    );

    // Actually click the notification link
    if (notificationLink) {
      notificationLink.click();
    }
    this.dispatchEvent(pdsCustomEvent);
  }

  /**
   * @returns The notification count, restricted to a range of 0-99 for display
   * @internal
   */
  cleanNotificationCount(count: number): number {
    if (count < 0) {
      return 0;
    }

    if (count > 99) {
      return 99;
    }

    return count;
  }

  /**
   * Handle click outside the notifications panel
   * @internal
   */
  handleOnClickOutsideNotifications(event: MouseEvent) {
    // If the notification panel is already closed then we don't care about outside clicks and we
    // can bail early
    if (!this.isPanelActive) {
      return;
    }

    // If clicking the notification bell or search icon again, bail here and let the toggle function take over
    if (
      (this.bellIcon && event.composedPath().includes(this.bellIcon)) ||
      (this.searchIcon && event.composedPath().includes(this.searchIcon))
    ) {
      return;
    }

    let didClickInside = false;

    // Check to see if we clicked inside the active navigation item
    if (this.notificationsPanel || this.searchPanel) {
      didClickInside =
        event.composedPath().includes(this.notificationsPanel) ||
        event.composedPath().includes(this.searchPanel);
    }

    // Only apply click outside breakpoint greater than or equal to 768px to get better
    // accordion behavior on small screens
    // TODO: can we tokenize this?
    if (window.innerWidth >= 768) {
      // If the navigation is active and we've clicked outside of the nav then it should be closed.
      if (this.isPanelActive && !didClickInside) {
        const pdsCustomEvent = new CustomEvent(
          'pds-primary-navigation-panel-close',
          {
            bubbles: true,
            composed: true,
            detail: {
              summary: `${this.searchPanel ? 'search' : 'notifications'} panel`,
            },
          },
        );

        this.dispatchEvent(pdsCustomEvent);

        this.isPanelActive = false;
      }
    }
  }

  /**
   * On escape key press, close the notification panel and return focus to the bell icon
   * @internal
   */
  handlePanelKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.isPanelActive = false;
      if (this.bellIcon && this.bellIcon.shadowRoot) {
        this.bellIcon.shadowRoot.querySelector('button')?.focus();
      } else if (this.searchIcon && this.searchIcon.shadowRoot) {
        this.searchIcon.shadowRoot.querySelector('button')?.focus();
      }
    }
  }

  /**
   * An object map of logo variants, keyed off the primary navigation variants
   * @internal
   */
  @state()
  logoVariants = {
    default: {
      logoVariant: 'default',
    },
    inverted: {
      logoVariant: 'white',
    },
  };

  /**
   * @internal
   */
  @state()
  popper: any;

  /**
   * Tracks the active class across all items in the navigation
   * @internal
   */
  @property({ type: Boolean })
  isActive: boolean;

  /**
   * Tracks whether the notification/search panel is active or not
   * @internal
   */
  @property({ type: Boolean })
  isPanelActive: boolean = false;

  /**
   * Called from menu button at mobile, closes notifications panel and opens navigation panel
   * @internal
   */
  toggleIsActive() {
    // Close all other open panels
    if (this.isPanelActive) {
      this.isPanelActive = false;
    }

    this.isActive = !this.isActive;

    const pdsCustomEvent = new CustomEvent(
      `pds-primary-navigation-menu-button-${
        this.isActive === true ? 'open' : 'close'
      }`,
      {
        bubbles: true,
        composed: true,
        detail: {
          summary: 'mobile primary navigation menu button',
        },
      },
    );

    this.dispatchEvent(pdsCustomEvent);
  }

  /**
   * Called from clicking notification bell or the search icon, closes other panels and opens the notification or search panel
   * @internal
   */
  togglePanelActive() {
    // Close all other open panels
    if (this.isActive) {
      this.isActive = false;
    }

    this.isPanelActive = !this.isPanelActive;

    const pdsCustomEvent = new CustomEvent(
      `pds-primary-navigation-panel-${
        this.isPanelActive === true ? 'open' : 'close'
      }`,
      {
        bubbles: true,
        composed: true,
        detail: {
          summary: `primary navigation panel`,
        },
      },
    );

    this.dispatchEvent(pdsCustomEvent);
  }

  handleClick(e: { target: { textContent: string } }) {
    const detailSummaryInfo = e.target.textContent.trim();
    const event = new CustomEvent('pds-primary-navigation-item-click', {
      bubbles: true,
      composed: true,
      detail: {
        summary: detailSummaryInfo,
      },
    });

    this.dispatchEvent(event);
  }

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'main-nav', selector: 'pds-main-nav' })
  mainNavs: HTMLElement[];

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'utility-nav', selector: 'pds-utility-nav' })
  utilityNavs: HTMLElement[];

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'main-nav', selector: 'main-nav-item' })
  mainNavItems: HTMLElement[];

  /**
   * Adds variant to main nav components
   * @internal
   */
  addVariantToMainNav() {
    if (this.mainNavs && this.mainNavs.length !== 0) {
      this.mainNavs.forEach((mainNav) => {
        mainNav.setAttribute('variant', this.variant);
      });
    }

    return '';
  }

  /**
   * Adds variant to utility nav components
   * @internal
   */
  addVariantToUtilityNav() {
    if (this.utilityNavs && this.utilityNavs.length !== 0) {
      this.utilityNavs.forEach((utilityNav) => {
        utilityNav.setAttribute('variant', this.variant);
      });
    }

    return '';
  }

  /**
   * Adds variant to utility nav components
   * @internal
   */
  getIconVariant() {
    return this.variant === 'default' ? 'icon' : 'icon-inverted';
  }

  /**
   * @internal
   */
  addVariantToNavDropdownLinks() {
    document.querySelectorAll('pds-nav-dropdown-link');

    const navDropdownLinks = Array.from(
      document.querySelectorAll('pds-nav-dropdown-link'),
    ) as HTMLElement[];

    navDropdownLinks.forEach((navDropdownLink) => {
      navDropdownLink.setAttribute('variant', this.variant);
    });
  }

  async firstUpdated(): Promise<void> {
    this.setWindowResizeHandler();
    // We need to wait for the update to complete to make sure that the primary nav elements are available
    // so we can adjust their variants
    await this.updateComplete;
    this.addVariantToNavDropdownLinks();
    this.addVariantToUtilityNav();
    this.addVariantToMainNav();
  }

  updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ): void {
    if (
      changedProperties.has('isPanelActive') ||
      changedProperties.has('responsiveViewportSize')
    ) {
      this.popper?.destroy();
      this.popper = undefined;
      if (
        this.popper === undefined &&
        this.isPanelActive &&
        (this.responsiveViewportSize === 'lg' ||
          this.responsiveViewportSize === 'xl')
      ) {
        const searchPanel = this.shadowRoot?.querySelector(
          `.${this.classEl('search-panel')}`,
        );
        const notificationsPanel = this.shadowRoot?.querySelector(
          `.${this.classEl('notification-panel')}`,
        );
        const poppedPanel = searchPanel || notificationsPanel;
        const poppingItem = this.searchIcon || this.bellIcon;

        if (poppedPanel && poppingItem) {
          this.popper = createPopper(poppingItem, poppedPanel as HTMLElement, {
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [128, 40],
                },
              },
            ],
          });
        }
      }
    }
  }

  /**
   * Link for the logo
   */
  @property({ type: String })
  logoHref: string = 'https://www.principal.com';

  /**
   * Link for the log in link button
   */
  @property({ type: String })
  loginLink: 'none' | 'login' | 'logout' = 'login';

  /**
   * Variant for the log in link; valid values are provided by the link component
   */
  @property({ type: String })
  loginLinkVariant: string = 'primary';

  /**
   * Href for the log in link button
   */
  @property({ type: String })
  loginLinkHref: string = 'https://login.principal.com/login';

  /**
   * Returns the log in link button markup
   * @internal
   */
  returnLogInMarkup() {
    if (this.loginLink !== 'none') {
      return html`<pds-link
          button="${this.loginLinkVariant}"
          href="${this.loginLinkHref}"
          ariaLabel="${this.loginLink === 'login'
            ? msg('Log in')
            : msg('Log out')}"
          role="button"
          class="${this.classEl('buttons-nav--desktop-button')}"
          @click=${this.handleClick}
          >${this.loginLink === 'login'
            ? msg('Log in')
            : msg('Log out')}</pds-link
        >
        <pds-link
          button="${this.loginLinkVariant}"
          href="${this.loginLinkHref}"
          size="sm"
          ariaLabel="${this.loginLink === 'login'
            ? msg('Log in')
            : msg('Log out')}"
          role="button"
          class="${this.classEl('buttons-nav--mobile-button')}"
          @click=${this.handleClick}
          >${this.loginLink === 'login'
            ? msg('Log in')
            : msg('Log out')}</pds-link
        >`;
    }

    return nothing;
  }

  /**
   * Returns the inner div markup
   * @internal
   */
  returnInnerDivMarkup() {
    return html`<div class="${this.classEl('inner')}">
      <a
        class="${this.classEl('skip-to-main')}"
        href="#main"
        @click=${this.handleClick}
        >${msg('Skip to content')}</a
      >
      <a
        class="${this.classEl('logo-link')}"
        href="${this.logoHref}"
        aria-label="${msg('Link to')} ${this.logoHref}"
        @click=${this.handleClick}
      >
        <span><slot name="logo"></slot></span>
        ${this.slotEmpty('logo')
          ? html`<pds-logo
                class="${this.classEl('logo--desktop')}"
                variant="${this.logoVariants[this.variant].logoVariant}"
              ></pds-logo
              ><pds-logo
                class="${this.classEl('logo--mobile')}"
                variant="${this.logoVariants[this.variant].logoVariant}"
              ></pds-logo>`
          : nothing}
      </a>
      <pds-nav-container
        variant="${this.variant}"
        class="${this.classEl('nav-container')}"
        @click=${this.toggleIsActive}
      >
        <slot name="main-nav" @slotchange=${this.addVariantToMainNav}></slot>
        <slot
          name="utility-nav"
          @slotchange=${this.addVariantToUtilityNav}
        ></slot>
      </pds-nav-container>
      <div
        class="${this.classEl('icons-nav')} ${this.isPanelActive
          ? 'pds-is-notification-active'
          : ''}"
      >
        ${(this.includeAction && this.includeAction === 'search') ||
        !this.slotEmpty('search')
          ? // This needs to be conditional based on inverted or default variant of nav
            html`<pds-button
                variant=${this.getIconVariant()}
                type="button"
                class="${this.classEl('search-icon')}"
                name="search"
                aria-expanded="${this.isPanelActive}"
                role="button"
                isActive=${this.isPanelActive ? true : nothing}
                ariaLabel="${msg('search')}"
                @click=${this.togglePanelActive}
              >
                ${this.isPanelActive
                  ? html`<pds-icon-x size="lg"></pds-icon-x>`
                  : html`<pds-icon-search
                      size="lg"
                    ></pds-icon-search>`}</pds-button
              >
              <div
                class="${this.classEl('search-panel')}"
                @click=${this.togglePanelActive}
              >
                <div data-popper-arrow></div>
                <div
                  class="${this.classEl('search-panel--inner-card')}"
                  @keydown=${this.handlePanelKeyDown}
                  @click=${(event: Event) => event.stopPropagation()}
                >
                  <slot name="search"></slot>
                </div>
              </div>`
          : nothing}
        ${this.includeAction &&
        this.includeAction === 'notification' &&
        this.slotEmpty('search') &&
        this.messagesCount === 0 &&
        this.otherAlertsCount === 0
          ? html`<pds-button
              variant=${this.getIconVariant()}
              type="button"
              @click=${this.togglePanelActive}
              class="${this.classEl('bell-icon')}"
              name="notifications"
              isActive=${this.isPanelActive ? true : nothing}
              aria-expanded=${this.isPanelActive}
              role="button"
              ariaLabel="${msg('notifications')}"
            >
              ${this.isPanelActive
                ? html`<pds-icon-x size="lg"></pds-icon-x>`
                : html`<pds-icon-bell size="lg"></pds-icon-bell>`}
            </pds-button>`
          : nothing}
        ${this.includeAction &&
        this.includeAction === 'notification' &&
        this.slotEmpty('search') &&
        ((this.messagesCount && this.messagesCount > 0) ||
          (this.otherAlertsCount && this.otherAlertsCount > 0))
          ? html`<pds-button
              variant=${this.getIconVariant()}
              type="button"
              @click=${this.togglePanelActive}
              class="${this.classEl('bell-icon')}"
              name="notifications"
              isActive=${this.isPanelActive ? true : nothing}
              aria-expanded=${this.isPanelActive}
              role="button"
              ariaLabel="${msg('notifications')}"
            >
              ${this.isPanelActive
                ? html`<pds-icon-x size="lg"></pds-icon-x>`
                : html`<pds-icon-bell-notification
                    size="lg"
                  ></pds-icon-bell-notification>`}
            </pds-button>`
          : nothing}
        ${this.includeAction &&
        this.includeAction === 'notification' &&
        this.slotEmpty('search')
          ? html`
              <div
                class="${this.classEl('notification-panel')}"
                @keydown=${this.handlePanelKeyDown}
                @click=${this.togglePanelActive}
              >
                <div data-popper-arrow></div>
                <div
                  class="${this.classEl('notification-panel--inner-card')}"
                  @click=${(event: Event) => event.stopPropagation()}
                >
                  <pds-list size="sm" spacing="none">
                    <pds-list-item
                      @click="${() => {
                        this.clickNotificationLink(
                          'pds-c-primary-nav-messages-link',
                        );
                      }}"
                      ><span
                        class="${this.classEl(
                          'notification-panel--left-column',
                        )}"
                        ><a
                          id="pds-c-primary-nav-messages-link"
                          href="${this.messagesHref}"
                          >${msg('Messages')}</a
                        ></span
                      >${this.messagesCount
                        ? html`<span
                            class="${this.classEl(
                              'notification-panel--right-column',
                            )}"
                            >${this.cleanNotificationCount(
                              this.messagesCount,
                            )}</span
                          >`
                        : nothing}</pds-list-item
                    >
                    ${!this.hideOtherAlerts
                      ? html` <pds-list-item
                          @click="${() =>
                            this.clickNotificationLink(
                              'pds-c-primary-nav-other-notifications-link',
                            )}"
                          ><span
                            class="${this.classEl(
                              'notification-panel--left-column',
                            )}"
                            ><a
                              id="pds-c-primary-nav-other-notifications-link"
                              href="${this.otherAlertsHref}"
                              >${msg('Other alerts')}</a
                            ></span
                          >${this.otherAlertsCount
                            ? html`<span
                                class="${this.classEl(
                                  'notification-panel--right-column',
                                )}"
                                >${this.cleanNotificationCount(
                                  this.otherAlertsCount,
                                )}</span
                              >`
                            : nothing}</pds-list-item
                        >`
                      : nothing}
                  </pds-list>
                </div>
              </div>
            `
          : nothing}
      </div>
      <span class="${this.classEl('buttons-nav')}">
        ${this.returnLogInMarkup()}
        ${!this.querySelector('[slot="main-nav"]')?.children.length &&
        !this.querySelector('[slot="utility-nav"]')?.children.length
          ? nothing
          : html`<pds-button
              variant=${this.getIconVariant()}
              ariaLabel="${msg('Menu')}"
              role="button"
              isActive=${this.isActive ? true : nothing}
              ariaExpanded=${this.isActive}
              @click=${this.toggleIsActive}
              class="${this.classEl('menu-button')}"
            >
              ${this.isActive
                ? html`<pds-icon-x size="lg"></pds-icon-x>`
                : html`<pds-icon-menu size="lg"></pds-icon-menu>`}
            </pds-button>`}
      </span>
    </div>`;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      'is-active': this.isActive,
      [`${this.variant}`]: !!this.variant,
      'remove-padding': typeof this.layoutContainerVariant !== 'undefined',
    };
  }

  /**
   * Attach functions
   */
  constructor() {
    super();
    this.handleOnClickOutsideNotifications =
      this.handleOnClickOutsideNotifications.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.setLocale();
    document.addEventListener(
      'mouseup',
      this.handleOnClickOutsideNotifications,
      false,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Remove window resize event listener
    document.removeEventListener(
      'mouseup',
      this.handleOnClickOutsideNotifications,
      false,
    );
  }

  render() {
    return html`<nav aria-label="primary navigation" class=${this.getClass()}>
      ${typeof this.layoutContainerVariant !== 'undefined'
        ? html`<pds-layout-container
            variant=${this.layoutContainerVariant}
            removePadding=${ifDefined(this.removeLayoutContainerPadding)}
            >${this.returnInnerDivMarkup()}</pds-layout-container
          >`
        : this.returnInnerDivMarkup()}
    </nav>`;
  }
}
