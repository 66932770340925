import * as React from 'react';
import { EventName } from '@lit-labs/react';
import { PdsMainNavItem as PdsMainNavItemElement } from '@principal/design-system';
import { PdsMainNavItemProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsMainNavItem = createPdsReactComponent<PdsMainNavItemProps>()({
  tagName: 'pds-main-nav-item',
  elementClass: PdsMainNavItemElement,
  react: React,
  events: {
    onClick: 'pds-main-nav-link-click' as EventName<CustomEvent>,
    onDropdownOpen: 'pds-main-nav-dropdown-open' as EventName<CustomEvent>,
    onDropdownClose: 'pds-main-nav-dropdown-close' as EventName<CustomEvent>,
  },
});
