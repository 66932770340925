import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { msg, localized } from '@lit/localize';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './footer.scss?inline';
import '@principal/design-system-icons-web/phone';
import '@principal/design-system-icons-web/help-circle';
import '@principal/design-system-icons-web/mail';
import '@principal/design-system-icons-web/facebook';
import '@principal/design-system-icons-web/twitter';
import '@principal/design-system-icons-web/linkedin';
import '@principal/design-system-icons-web/youtube';
import '@principal/design-system-icons-web/instagram';
import '@principal/design-system-icons-web/whatsapp';
import '../band/band';
import '../collapsible/collapsible';
import '../footer-nav/footer-nav';
import '../footer-nav-item/footer-nav-item';
import '../heading/heading';
import '../layout-container/layout-container';
import '../link/link';
import '../list/list';
import '../list-item/list-item';
import '../logo/logo';

export type SocialIconObject = {
  type:
    | 'facebook'
    | 'linkedin'
    | 'twitter'
    | 'youtube'
    | 'instagram'
    | 'whatsapp';
  url: string;
  ariaLabel: string;
};

/**
 * @summary The Principal footer component
 *
 * @slot default A pds-footer-nav element containing one or more pds-footer-nav-item elements
 * @slot heading A slot for a heading for the footer
 * @slot additional-info Adds a additional info section into the superFooter
 * @slot legal-text A slot for an optional custom legal text
 * @slot logo A slot for an optional custom logo
 */
@customElement('pds-footer', {
  category: 'component',
  type: 'component',
  styles,
})
// This is important to place below the @customElement decorator (https://github.com/lit/lit/issues/3264)
@localized()
export class PdsFooter extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.setLocale();
  }
  /**
   * - **default** renders the footer-nav used for primary actions on a dark background
   * - **subtle** renders the footer-nav-item used for primary actions on a subtle background
   */

  @property({ type: String })
  variant: 'default' | 'subtle' = 'default';

  /**
   * Primary contact phone number
   */
  @property({ type: String })
  contactPhone: string = '800-986-3343';

  /**
   * Web link for help content
   */
  @property({ type: String })
  helpLink: string = 'https://www.principal.com/help';

  /**
   * Web link for contact us content
   */
  @property({ type: String })
  contactLink: string = 'https://www.principal.com/contact-us';

  /**
   * Web link for terms of use guidelines
   */
  @property({ type: String })
  termsOfUseLink: string = 'https://www.principal.com/terms-of-use';

  /**
   * Web link for disclosures
   */
  @property({ type: String })
  disclosuresLink: String =
    'https://www.principal.com/products-services-disclosures';

  /**
   * Web link for privacy policy
   */
  @property({ type: String })
  privacyLink: string = 'https://www.principal.com/privacy-policies';

  /**
   * Web link for security policy
   */
  @property({ type: String })
  securityLink: string = 'https://www.principal.com/security-policies';

  /**
   * Web link for reporting fraud
   */
  @property({ type: String })
  reportFraudLink: String =
    'https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct';

  /**
   * Add customLinks
   */
  @property({ type: Array })
  customLinks?: { label: string; href: string }[] = [];

  /**
   * Context-specific footer behavior
   * - **login** provides a link for login assistance
   * - **super** provides logo above an optional footer nav populated with footer nav items
   */
  @property({ type: String })
  behavior: 'login' | 'super' | 'default' = 'default';

  /**
   * The social icons to display in the footer; should be passed in as an array of social icon objects,
   * for example: [{type: 'facebook', url: 'https://facebook.com', ariaLabel: 'My company on Facebook'}]
   * Should be passed in as an empty array if no social icons are desired
   * - **type** the social icon type. Current options are facebook, twitter, youtube, linkedin, instagram, and whatsapp.
   * - **url** the social icon url
   * - **ariaLabel** the Aria Label text for screen-readers
   */
  @property({ type: Array<SocialIconObject> })
  socialIcons: Array<SocialIconObject>;

  /**
   * The default social icons to display in the footer if nothing is passed in to the socialIcons prop
   * @internal
   */
  @property({ type: Array<SocialIconObject> })
  defaultSocialIcons: SocialIconObject[] = [
    {
      type: 'facebook',
      url: 'https://facebook.com/PrincipalFinancial',
      ariaLabel: msg('Principal on Facebook'),
    },
    {
      type: 'twitter',
      url: 'https://twitter.com/Principal',
      ariaLabel: msg('Principal on Twitter'),
    },
    {
      type: 'youtube',
      url: 'https://youtube.com/PrincipalFinancial',
      ariaLabel: msg('Principal on YouTube'),
    },
    {
      type: 'linkedin',
      url: 'https://linkedin.com/company/principalfinancialgroup',
      ariaLabel: msg('Principal on LinkedIn'),
    },
  ];

  /**
   * Link for the logo
   */
  @property({ type: String })
  logoHref: string = 'https://www.principal.com';

  /**
   * Aria label for the logo
   */
  @property()
  logoAriaLabel: String;

  /**
   * Set to true to hide the logo in a super footer. This is NOT recommended unless you have a sticky header that will cause incorrect vertical alignment on the page.
   */
  @property({ type: Boolean })
  hideLogo: boolean = false;

  /**
   * Aria label for legal-nav
   */
  @property()
  legalNavAriaLabel: String;

  /**
   * variant for layout container
   * - **narrow** renders the layout-container narrower than the default
   */
  @property({ type: String })
  layoutContainerVariant: 'default' | 'narrow' = 'default';

  /**
   * Remove layout container padding
   * - **md** removes padding from the layout container below md breakpoint
   * - **all** removes padding from the layout container at all screens (used for nested layout containers)
   */
  @property({ type: String })
  removeLayoutContainerPadding?: 'md' | 'all';

  /**
   * @internal
   */
  @queryAssignedElements({ slot: undefined, selector: 'pds-footer-nav' })
  footerNavs: HTMLElement[];

  /**
   * @internal
   */
  addVariantToFooterNavs() {
    const collapsibleVariant =
      this.variant === 'default' ? 'inverted' : 'strong';

    if (this.footerNavs && this.footerNavs.length !== 0) {
      this.footerNavs.forEach((footerNavItem) => {
        footerNavItem.setAttribute('variant', collapsibleVariant);
      });
    }

    return '';
  }

  firstUpdated() {
    this.addVariantToFooterNavs();
  }

  /**
   * @internal
   */
  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }

  /**
   * @internal
   */
  setLinkVariant() {
    return this.variant === 'default' ? 'light' : '';
  }

  /**
   * @internal
   */
  setBandVariant() {
    return this.variant === 'default' ? 'strong' : 'subtle';
  }

  /**
   * @internal
   */
  setCollapsibleVariant() {
    return this.variant === 'default' ? 'inverted' : 'strong';
  }

  /**
   * @internal
   */
  loginSupport() {
    return html`<div class="${this.classEl('login-support')}">
      ${msg('Trouble logging in?')}
      <pds-link variant="emphasis" href="${this.helpLink}"
        >${msg('Get help')}</pds-link
      >
    </div>`;
  }

  /**
   * @internal
   * If the user passes in a custom aria-label, that will be populated.
   * If not, the label will be automated and language localized.
   */
  getLogoAriaLabel() {
    if (!this.logoAriaLabel) {
      const localizedAriaLabel = msg('Link to Principal homepage');
      return localizedAriaLabel;
    }
    if (this.logoAriaLabel && this.logoAriaLabel !== 'false') {
      return this.logoAriaLabel;
    }
    return nothing;
  }

  /**
   * @internal
   */
  getLegalNavAriaLabel() {
    if (this.legalNavAriaLabel) {
      return this.legalNavAriaLabel;
    }

    return msg('Select an option for more information about Principal');
  }

  /**
   * @internal
   */
  setPrincipalLogo() {
    return this.variant === 'default'
      ? html`<div class="${this.classEl('logo')}">
          <a
            class="${this.classEl('logo-link')}"
            href="${this.logoHref}"
            aria-label="${this.getLogoAriaLabel()}"
          >
            <pds-logo variant="default-p-white-font"></pds-logo>
          </a>
        </div>`
      : html`<div class="${this.classEl('logo')}">
          <a
            class="${this.classEl('logo-link')}"
            href="${this.logoHref}"
            aria-label="${this.getLogoAriaLabel()}"
          >
            <pds-logo variant="default"></pds-logo>
          </a>
        </div>`;
  }

  /**
   * @internal
   */
  setCustomPrincipalLogo() {
    return html`<div class="${this.classEl('logo')}">
      <a
        class="${this.classEl('logo-link')}"
        href="${this.logoHref}"
        aria-label="${this.getLogoAriaLabel()}"
      >
        <slot name="logo"></slot>
      </a>
    </div>`;
  }

  /**
   * This method returns a single social icon element string
   * @internal
   */
  returnSocialIconMarkup(socialIcon: SocialIconObject) {
    return `<pds-list-item
          ><pds-link
            variant="${this.setLinkVariant()}"
            href="${socialIcon.url}"
            ariaLabel="${socialIcon.ariaLabel}"
            target="_blank"
            ><pds-icon-${socialIcon.type}
              size="xl"
              color="currentColor"
            ></pds-icon-${socialIcon.type}> </pds-link
        ></pds-list-item>`;
  }

  /**
   * This method returns the concatenated html markup of all the social icons that were passed in by the consumer
   * @internal
   */
  setSocialIcons(socialIcons: Array<SocialIconObject>) {
    let socialIconMarkup = '';
    socialIcons.forEach((socialIcon) => {
      socialIconMarkup = `${socialIconMarkup}${this.returnSocialIconMarkup(
        socialIcon,
      )}`;
    });

    // Note: We had to use this in order to render the social icons as Templateresult types rather than strings; tried multiple different ways to manage this, and this was the only way that worked.
    // Would love to revisit this with BM to see if there's a different way to concatenate templateresult elements
    return unsafeHTML(`${socialIconMarkup}`);
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<pds-band
      variant=${this.setBandVariant()}
      spacing="default"
      class=${this.getClass()}
    >
      <pds-layout-container
        variant=${this.layoutContainerVariant}
        removePadding=${ifDefined(this.removeLayoutContainerPadding)}
        class="${this.behavior === 'super' || this.behavior === 'login'
          ? this.classMod(this.behavior)
          : nothing}"
      >
        ${!this.slotEmpty('logo') && this.behavior === 'super' && !this.hideLogo
          ? this.setCustomPrincipalLogo()
          : nothing}
        <slot name="logo" class="${this.classEl('logo')}"></slot>
        ${this.slotEmpty('logo') && this.behavior === 'super' && !this.hideLogo
          ? this.setPrincipalLogo()
          : nothing}
        <slot @slotchange=${this.addVariantToFooterNavs}></slot>
        <slot name="heading"></slot>
        ${this.slotNotEmpty('additional-info') &&
        html`
          <div class=${this.classEl('additional-info')}>
            <slot name="additional-info"></slot>
          </div>
        `}
        <nav
          class=${this.classEl('navigation-area')}
          aria-label="${msg('Footer navigation')}"
        >
          ${this.behavior === 'login' ? this.loginSupport() : ''}
          <nav
            class=${this.classEl('contact-navigation')}
            aria-label="${msg('Contact us navigation')}"
          >
            ${this.contactPhone === 'none' &&
            this.contactLink === 'none' &&
            this.helpLink === 'none'
              ? nothing
              : html`<pds-collapsible
                  class="${this.classEl('collapsible')}"
                  variant=${this.setCollapsibleVariant()}
                >
                  <pds-heading
                    variant="label-default"
                    headingTag="h2"
                    slot="summary-title"
                    >${msg('Contact')}</pds-heading
                  >
                  <span slot="collapsible-content">
                    <pds-list title="Contact">
                      ${this.contactPhone === 'none'
                        ? nothing
                        : html`
                            <pds-list-item>
                              <pds-link
                                variant=${this.setLinkVariant()}
                                href="tel:${this.contactPhone}"
                                ariaLabel="${msg('Call us at')} ${this
                                  .contactPhone}"
                                ><pds-icon-phone
                                  slot="icon-left"
                                ></pds-icon-phone>
                                ${this.contactPhone}</pds-link
                              >
                            </pds-list-item>
                          `}
                      ${this.helpLink === 'none'
                        ? nothing
                        : html` <pds-list-item>
                            <pds-link
                              variant=${this.setLinkVariant()}
                              href="${this.helpLink}"
                              ><pds-icon-help-circle
                                slot="icon-left"
                              ></pds-icon-help-circle>
                              ${msg('Help topics')}</pds-link
                            >
                          </pds-list-item>`}
                      ${this.contactLink === 'none'
                        ? nothing
                        : html` <pds-list-item>
                            <pds-link
                              variant=${this.setLinkVariant()}
                              href="${this.contactLink}"
                              ><pds-icon-mail slot="icon-left"></pds-icon-mail>
                              ${msg('Contact us')}</pds-link
                            >
                          </pds-list-item>`}
                    </pds-list>
                  </span>
                </pds-collapsible>`}
            ${this.contactPhone === 'none' &&
            this.contactLink === 'none' &&
            this.helpLink === 'none'
              ? nothing
              : html` <pds-list
                  orientation="horizontal"
                  class="${this.classEl('contact-row')}"
                  title="Contact"
                >
                  ${this.contactPhone === 'none'
                    ? nothing
                    : html` <pds-list-item>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="tel:${this.contactPhone}"
                          ariaLabel="${msg('Call us at')} ${this.contactPhone}"
                          ><pds-icon-phone slot="icon-left"></pds-icon-phone>
                          ${this.contactPhone}</pds-link
                        >
                      </pds-list-item>`}
                  ${this.helpLink === 'none'
                    ? nothing
                    : html` <pds-list-item>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.helpLink}"
                          ><pds-icon-help-circle
                            slot="icon-left"
                          ></pds-icon-help-circle>
                          ${msg('Help topics')}</pds-link
                        >
                      </pds-list-item>`}
                  ${this.contactLink === 'none'
                    ? nothing
                    : html` <pds-list-item>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.contactLink}"
                          ><pds-icon-mail slot="icon-left"></pds-icon-mail>
                          ${msg('Contact us')}</pds-link
                        >
                      </pds-list-item>`}
                </pds-list>`}
          </nav>
          <nav
            class="${this.classEl('legal-nav')}"
            aria-label="${this.getLegalNavAriaLabel()}"
          >
            <pds-list
              class="${this.classEl('extra-links')}"
              orientation="horizontal"
            >
              <pds-list-item>
                <pds-link
                  variant=${this.setLinkVariant()}
                  href="${this.termsOfUseLink}"
                  >${msg('Terms of use')}</pds-link
                >
              </pds-list-item>
              <pds-list-item>
                <pds-link
                  variant=${this.setLinkVariant()}
                  href="${this.disclosuresLink}"
                  >${msg('Disclosures')}</pds-link
                >
              </pds-list-item>
              <pds-list-item>
                <pds-link
                  variant=${this.setLinkVariant()}
                  href="${this.privacyLink}"
                  >${msg('Privacy')}</pds-link
                >
              </pds-list-item>
              <pds-list-item>
                <pds-link
                  variant=${this.setLinkVariant()}
                  href="${this.securityLink}"
                  >${msg('Security')}</pds-link
                >
              </pds-list-item>
              <pds-list-item>
                <pds-link
                  variant=${this.setLinkVariant()}
                  href="${this.reportFraudLink}"
                  >${msg('Report fraud')}</pds-link
                >
              </pds-list-item>
              ${this.customLinks &&
              this.customLinks.map(
                (link) =>
                  html`<pds-list-item>
                    <pds-link
                      variant=${this.setLinkVariant()}
                      href="${link.href}"
                      >${link.label}</pds-link
                    >
                  </pds-list-item>`,
              )}
            </pds-list>
          </nav>
          <div class="${this.classEl('copyright-and-social')}">
            <div class="${this.classEl('legal')}">
              <slot
                name="legal-text"
                class="${this.classEl('legal-text-container')}"
              >
                <p class="${this.classEl('legal-text')}">
                  ©${this.getCurrentYear()}, Principal Financial Services, Inc.
                </p>
                <p class="${this.classEl('legal-text')}">
                  ${msg(
                    'Securities offered through Principal Securities, Inc.,',
                  )}
                  <pds-link
                    variant="emphasis"
                    size="sm"
                    href="http://www.sipc.org"
                    target="_blank"
                    >${msg('member SIPC')}</pds-link
                  >
                </p>
              </slot>
            </div>
            <nav
              class=${this.classEl('social-navigation')}
              aria-label="${msg('Social media navigation')}"
            >
              ${this.socialIcons && this.socialIcons.length
                ? html`<pds-list
                    class="${this.classEl('social-icons')}"
                    orientation="horizontal"
                  >
                    ${this.setSocialIcons(this.socialIcons)}
                  </pds-list>`
                : nothing}
              ${!this.socialIcons
                ? html`<pds-list
                    class="${this.classEl('social-icons')}"
                    orientation="horizontal"
                  >
                    ${this.setSocialIcons(this.defaultSocialIcons)}
                  </pds-list>`
                : nothing}
            </nav>
          </div>
        </nav>
      </pds-layout-container>
    </pds-band>`;
  }
}
