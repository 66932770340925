import { html } from 'lit';
import { property, query, queryAssignedElements } from 'lit/decorators.js';
import { Instance, createPopper } from '@popperjs/core';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tooltip.scss?inline';

/**
 * @summary This component provides a tooltip when a user hovers or focuses on an icon
 *
 * @slot default Provides the inner contents of the tooltip
 * @slot trigger This slot contains the element from which the tooltip is triggered
 */
@customElement('pds-tooltip', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTooltip extends PdsElement {
  /**
   * Style variant
   * - **default** renders the standard variant, with dark-colored tooltips.
   * - **subtle** renders the subtle variant, with light-colored tooltips.
   */
  @property()
  variant: 'default' | 'subtle' = 'default';

  /**
   * Position for tooltip
   * - **top** renders the tooltip at top.
   * - **right** renders the tooltip at right.
   * - **bottom** renders the tooltip at bottom.
   * - **left** renders the tooltip at left.
   */
  @property()
  placement: 'top' | 'right' | 'bottom' | 'left' = 'top';

  /**
   * This grabs the div element of tooltip
   * @internal
   */
  @query('.pds-c-tooltip__tooltip')
  popperPopup: HTMLElement;

  /**
   * This grabs the div element of arrow
   * @internal
   */
  @query('.pds-c-tooltip__arrow')
  popperArrow: HTMLElement;

  /**
   * Adds a aria-label to the tooltip
   */
  @property()
  ariaLabel: string;

  /**
   * This grabs the tooltip trigger element
   * @internal
   */
  @query('.pds-c-tooltip__trigger')
  popperTriggerSlot: HTMLSlotElement;

  private popperInstance: Instance;

  createInstance() {
    const button = this.popperTriggerSlot.assignedElements()[0];
    if (button) {
      this.popperInstance = createPopper(button, this.popperPopup, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-4, 26],
            },
          },
        ],
      });
    }
  }

  destroyInstance() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }

  async show() {
    // Make the tooltip visible
    this.popperPopup.setAttribute('data-show', '');
    this.popperArrow.setAttribute('data-popper-arrow', '');
    this.popperArrow.setAttribute('aria-expanded', 'true');
    this.createInstance();

    // Update its position
    await this.popperInstance.update();
  }

  hide() {
    // Hide the tooltip
    this.popperPopup.removeAttribute('data-show');
    this.popperArrow.removeAttribute('data-popper-arrow');
    this.popperArrow.setAttribute('aria-expanded', 'false');
    this.destroyInstance();
  }

  @queryAssignedElements({ slot: 'trigger' })
  trigger: HTMLElement[];

  firstUpdated() {
    const trigger = this.trigger[0];
    const showEvents = ['mouseenter', 'focus'];
    const toggleEvents = ['touchstart'];
    const leaveEvents = ['mouseleave', 'blur'];

    showEvents.forEach((event) => {
      trigger.addEventListener(event, () => {
        this.show();
      });
    });

    leaveEvents.forEach((event) => {
      trigger.addEventListener(event, () => {
        this.hide();
        trigger.blur();
      });
    });

    toggleEvents.forEach((event) => {
      trigger.addEventListener(event, () => {
        this.handleToggle();
      });
    });
  }

  handleToggle() {
    if (this.popperPopup.hasAttribute('data-show')) {
      this.hide();
    } else {
      this.show();
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<div class=${this.getClass()}>
      <slot
        name="trigger"
        aria-describedby="tooltip-container"
        class=${this.classEl('trigger')}
      ></slot>
      <span
        id="tooltip-container"
        class="${this.classEl('tooltip')}"
        aria-label="${this.ariaLabel}"
        role="tooltip"
      >
        <slot></slot>
        <div class="${this.classEl('arrow')}" data-popper-arrow></div>
      </span>
    </div> `;
  }
}
