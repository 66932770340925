import { PdsAlert } from "@principal/design-system-react";

export function FeedbackMessage(props:any) {
    const validationStatus = props.validationStatus;
    const submittedStatus = props.submittedStatus;
    const errorMessages = props.errors;
    return getDisplayValue(errorMessages, validationStatus, submittedStatus);
}

function getDisplayValue(errors: any, validated: boolean, submitted: boolean) {

    if (!validated  && errors.length === 0 && errors.toString() === "") {  // New screen
        return (<div></div>)
    }
    else if (!validated  && errors.length !== 0 && errors.toString() !== "") {  // Errors found
        const errorMessages = errors.map((error:string)=>
            <li key="errorDisplay1">{errors}</li>
        )
        
        if (errors.length === 1) {
            return (
                <PdsAlert variant="error" role="alert">
                    An error was detected.    
                    {errorMessages}
                </PdsAlert>
            )
        }
        else {
            const errorMessages = errors.map((thisError:string)=>
                <li key="errorDisplay2">{thisError}</li>
            )

            return (
                <PdsAlert variant="error" role="alert">
                    <p>
                        There were {errors.length} errors detected.   
                        {errorMessages}
                    </p>                    
                </PdsAlert>
            )
        }
        
    }
    else if (validated  && errors.length === 0) {    // Success
        
        if (submitted) {
            return (
                <PdsAlert variant="success">
                    Submission complete!
                </PdsAlert>
            )
        }
        else {
            return (
                <PdsAlert variant="success">
                    Validated!  Your upload is ready for submission.
                </PdsAlert>
            )
        }
    }
    else {
        return (
            <PdsAlert variant="banner">
                Welcome
            </PdsAlert>
        )
    }
}
