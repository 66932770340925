import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './utility-nav.scss?inline';
import '../hr/hr';

/**
 * @summary This component is a navigational element (nav) that holds an Un unordered list element (ul)
 *
 * @slot default One or more pds-utility-nav-item elements
 */
// TODO: should this be a layout component?  Need to switch that.
@customElement('pds-utility-nav', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsUtilityNav extends PdsElement {
  /**
   * Style variant
   * - **default** renders the default utility-nav
   * - **inverted** renders the inverted utility-nav
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<pds-hr class="pds-c-utility-nav__divider"></pds-hr>
      <nav class=${this.getClass()}>
        <ul class="pds-c-utility-nav__list" role="list">
          <slot></slot>
        </ul>
      </nav>`;
  }
}
