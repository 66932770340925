import * as React from 'react';
import { PdsSection as PdsSectionElement } from '@principal/design-system';
import { PdsSectionProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsSection = createPdsReactComponent<PdsSectionProps>()({
  tagName: 'pds-section',
  elementClass: PdsSectionElement,
  react: React,
});
