import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './main-nav.scss?inline';

/**
 * @summary This component is a navigational element (nav) that holds an Un unordered list element (ul)
 *
 * @slot default One or more pds-main-nav-item elements
 */
@customElement('pds-main-nav', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsMainNav extends PdsElement {
  /**
   * Style variant
   * - default treatment uses a default background
   * - **inverted** variant changes to the inverted background
   */
  @property()
  variant: 'default' | 'inverted' | 'secondary-nav' = 'default';

  /**
   * Screen reader label for button
   */
  @property({ type: String })
  ariaLabel: string;

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<div
      class=${this.getClass()}
      aria-label=${ifDefined(this.ariaLabel)}
    >
      <ul class="pds-c-main-nav__list" role="list">
        <slot></slot>
      </ul>
    </div>`;
  }
}
