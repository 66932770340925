import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './section.scss?inline';

/**
 * @summary This component renders a section element
 *
 * @slot header The header of the section
 * @slot default A slot for the body of the section
 */
@customElement('pds-section', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsSection extends PdsElement {
  /**
   * Align variant
   * - Default section is left aligned
   * - **center** aligns the header section
   */
  @property()
  align?: 'center';

  /**
   * @internal
   */
  get classNames() {
    return {
      [`align-${this.align}`]: !!this.align,
    };
  }

  render() {
    return html`<section class=${this.getClass()}>
      ${this.slotNotEmpty('header') &&
      html`
        <header class="pds-c-section__header">
          <slot name="header"></slot>
        </header>
      `}
      <slot></slot>
    </section>`;
  }
}
