import "@pds/navigationPrimary/navigationPrimary.min.css";

export function Header() {
  
  return <nav
    className="pds-navigationPrimary pds-navigationPrimary-primary pds-enterpriseHeader"
    data-settings-logout
    data-settings-notifications
    data-settings-notifications-multiple
    data-settings-persona="employer"
    data-settings-notifications-custom='{"notificationsCustom": [{"name": "Alerts", "url": "http://www.google.com", "newTab": "false", "id": "test", "unreadCount": "5"}]}'
  >
    <div className="pds-navigationPrimary-container container">
      <div className="pds-navigationPrimary-logo">
        <a
          data-gtm="navigation-primary"
          className="pds-navigationPrimary-logo-link pds-link-unstyled"
          href="https://www.principal.com"
          aria-label="Principal Homepage"
        >
          <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
        </a>
      </div>
      <div className="pds-navigationPrimary-menu-container">
        <ul className="pds-navigationPrimary-tier-one"></ul>
      </div>
      <div className="pds-navigationPrimary-actions-container pds-navigationPrimary-tier-one-menu-item"></div>
    </div>
  </nav>
}
