import CSS from "csstype";

export const TitleStyle:CSS.Properties={
    textAlign: "left",
    font: "normal normal 300 45px/55px FS Elliot Pro",
    letterSpacing: "0px",
    opacity: "1"
  };

export const HeaderStyle:CSS.Properties={
    textAlign: "left",
    font: "normal normal 300 18px FS Elliot Pro",
    letterSpacing: "0px",
    opacity: "1"
  };


