import * as React from 'react';
import { PdsUtilityNav as PdsUtilityNavElement } from '@principal/design-system';
import { PdsUtilityNavProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsUtilityNav = createPdsReactComponent<PdsUtilityNavProps>()({
  tagName: 'pds-utility-nav',
  elementClass: PdsUtilityNavElement,
  react: React,
});
