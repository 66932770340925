import { html } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './list.scss?inline';

export const size = ['sm', 'md'] as const;
export type ListSize = (typeof size)[number];

/**
 * @summary An unordered list element (ul)
 *
 * @slot default One or more pds-list-item elements
 */
@customElement('pds-list', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsList extends PdsElement {
  /**
   * Size property
   * sm - Renders the list and child list items with small typography
   * also adjusts the icon size if provided
   */
  @property()
  size: ListSize = 'md';

  @property()
  spacing: 'sm' | 'md' | 'lg' | 'none' = 'md';

  /**
   * Align variant
   * - Default section is left aligned
   * - **center** horizontally center aligns the list
   */
  @property()
  align: 'center' | 'default' = 'default';

  /**
   * Style variant
   * - **primary** renders the list used for primary actions
   */
  @property()
  orientation: 'horizontal' | 'default' = 'default';

  /**
   * @internal
   */
  get classNames() {
    return {
      /* This is equivalent to doing
       * 'primary': this.variant === 'primary',
       * 'secondary': this.variant === 'secondary',
       */
      [`${this.orientation}`]: !!this.orientation,
      [`align-${this.align}`]: !!this.align,
      [`${this.size}`]: !!this.size,
      [`spacing-${this.spacing}`]: !!this.spacing,
    };
  }

  /**
   * This grabs the listItem slots
   * @internal
   */
  @queryAssignedElements({ slot: undefined, selector: 'pds-list-item' })
  listItems: HTMLElement[];

  addSizeTolistItems() {
    if (this.listItems && this.listItems.length && size.includes(this.size)) {
      this.listItems.forEach((listItem) =>
        listItem.setAttribute('size', this.size),
      );
    }
  }

  updated() {
    this.addSizeTolistItems();
  }

  render() {
    return html`<ul class=${this.getClass()} part="list" role="list">
      <slot @slotchange=${this.addSizeTolistItems}></slot>
    </ul>`;
  }
}
