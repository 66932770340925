import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './footer-nav-item.scss?inline';
import '../list/list';
import '../list-item/list-item';
import '../link/link';
import '../heading/heading';
import '../collapsible/collapsible';

/**
 * @summary A component that renders a single link list at desktop and a collapsible at mobile
 */
@customElement('pds-footer-nav-item', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsFooterNavItem extends PdsElement {
  /**
   * - **default** renders the footer-nav-item used for primary actions on a dark background
   * - **subtle** renders the footer-nav-item used for primary actions on a subtle background
   */
  @property()
  variant: 'default' | 'subtle' = 'default';

  /**
   * Top level label for the nav item group
   */
  @property()
  label: string = '';

  /**
   * Array of key value pairs representing links in the nav item
   * For example, [{"title": "About us", "href": "/about"}, {"title": "Investor relations", "href": "/investor-relations"}]
   */
  @property({ type: Array<{ title: string; href: string }> })
  items: Array<{ title: string; href: string }>;

  /**
   * This turns the label prop to an alphanumeric value and replaces spaces with hyphens
   * @internal
   */
  @state()
  labelForID = this.label
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

  /**
   * @internal
   */
  renderLinkItems() {
    if (this.items && this.items.length !== 0) {
      return this.items.map((item: any) => {
        return html`<pds-list-item
          ><pds-link href="${item.href}">${item.title}</pds-link></pds-list-item
        >`;
      });
    }

    return '';
  }

  /**
   * @internal
   */
  getDesktopMarkup() {
    return html`
      <pds-heading
        id="${this.labelForID}-desktop"
        headingTag="h3"
        variant="label-default"
        >${this.label}</pds-heading
      >
      <nav aria-labelledby="${this.labelForID}-desktop">
        <pds-list>${this.renderLinkItems()}</pds-list>
      </nav>
    `;
  }

  /**
   * @internal
   */
  getMobileMarkup() {
    return html`
      <pds-collapsible variant=${this.variant}>
        <pds-heading
          id="${this.labelForID}-mobile"
          variant="label-default"
          headingTag="h2"
          slot="summary-title"
          >${this.label}</pds-heading
        >
        <div slot="collapsible-content">
        <nav aria-labelledby="${this.labelForID}-mobile">
          <pds-list>
            ${this.renderLinkItems()}
          </pds-list>
          </div></pds-collapsible>
        </nav>
    `;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<div class=${this.getClass()}>
      <div class="${this.classMod('desktop')}">${this.getDesktopMarkup()}</div>
      <div class="${this.classMod('mobile')}">${this.getMobileMarkup()}</div>
    </div>`;
  }
}
