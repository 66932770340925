import * as React from 'react';
import { EventName } from '@lit-labs/react';
import { PdsPrimaryNavigation as PdsPrimaryNavigationElement } from '@principal/design-system';
import { createPdsReactComponent } from '../../create-pds-react-component';

// TODO: fix with real type
type PdsPrimaryNavigationProps = Record<string, any>;

export const PdsPrimaryNavigation =
  createPdsReactComponent<PdsPrimaryNavigationProps>()({
    tagName: 'pds-primary-navigation',
    elementClass: PdsPrimaryNavigationElement,
    react: React,
    events: {
      onHandleClick:
        'pds-primary-navigation-item-click' as EventName<CustomEvent>,
      onNotificationLinkClick:
        'pds-primary-navigation-notification-link-click' as EventName<CustomEvent>,
      onPanelOpen:
        'pds-primary-navigation-panel-open' as EventName<CustomEvent>,
      onPanelClose:
        'pds-primary-navigation-panel-close' as EventName<CustomEvent>,
      onButtonOpen:
        'pds-primary-navigation-menu-button-open' as EventName<CustomEvent>,
      onButtonClose:
        'pds-primary-navigation-menu-button-close' as EventName<CustomEvent>,
    },
  });
