import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './description-list-item.scss?inline';

/**
 * @summary A description list item (name-value group)
 *
 * @slot description-term A slot for the name of the item being described
 * @slot default A slot for the details of the item being described
 */

@customElement('pds-description-list-item', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsDescriptionListItem extends PdsElement {
  @property()
  variant: 'primary' | 'reverse' = 'primary';

  render() {
    return html`
      <dt
        class=${this.variant === 'reverse'
          ? this.classEl('term-reverse')
          : this.classEl('term')}
      >
        <slot name="description-term"></slot>
      </dt>
      <dd
        class="pds-c-description-list-item-details ${this.variant === 'reverse'
          ? this.classEl('details-reverse')
          : this.classEl('details')}"
      >
        <slot></slot>
      </dd>
    `;
  }
}
