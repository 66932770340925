import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './description-list.scss?inline';
import { PdsDescriptionListItem } from '../description-list-item/description-list-item';

/**
 * @summary A description list (list of name-value groups). To be used with description-list-item
 *
 * @slot default A slot for <pds-description-list-item> components
 */
@customElement('pds-description-list', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsDescriptionList extends PdsElement {
  /**
   * variant
   * - **primary** - renders the list with default typography styles
   * - **reverse** - renders the list with reverse typography styles
   */
  @property()
  variant: 'primary' | 'reverse' = 'primary';

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  setChildVariant() {
    const descriptionListItem = Array.from(
      this.querySelectorAll('pds-description-list-item'),
    ) as PdsDescriptionListItem[];
    descriptionListItem.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.variant = this.variant;
    });
  }

  render() {
    return html`<dl class=${this.getClass()}>
      <slot @slotchange=${this.setChildVariant()}></slot>
    </dl>`;
  }
}
