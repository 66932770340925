import * as React from 'react';
import { EventName } from '@lit-labs/react';
import { PdsSwitch as PdsSwitchElement } from '@principal/design-system';
import { PdsSwitchProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsSwitch = createPdsReactComponent<PdsSwitchProps>()({
  tagName: 'pds-switch',
  elementClass: PdsSwitchElement,
  react: React,
  events: {
    onChange: 'pds-switch-change' as EventName<CustomEvent>,
    onFocus: 'pds-switch-focus' as EventName<CustomEvent>,
    onBlur: 'pds-switch-blur' as EventName<CustomEvent>,
  },
});
