import * as React from 'react';
import { PdsSecondaryNavigation as PdsSecondaryNavigationElement } from '@principal/design-system';
import { PdsSecondaryNavigationProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsSecondaryNavigation =
  createPdsReactComponent<PdsSecondaryNavigationProps>()({
    tagName: 'pds-secondary-navigation',
    elementClass: PdsSecondaryNavigationElement,
    react: React,
  });
