import '@principal/design-system/pds-styles.min.css';
import "@pds-react/core/dist/core.min.css";
import "@pds-react/grid/dist/grid.min.css";
import { PdsFooter } from "@principal/design-system-react";
import "@pds-react/fileUpload/dist/fileUpload.min.css";
import {Header} from "./components/Header";
import {Container, Row} from '@pds-react/grid';
import {HeaderStyle, TitleStyle} from "./components/FontStyles";
import {FileUploads} from "./components/FileUploads";
import {ValidationAndSubmit} from "./components/ValidationAndSubmit";
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./authConfig";

function ErrorComponent(props: any) {
    return <p>An Error Occurred: {props.error.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

function App() {
    const [submitted, setSubmitted] = useState(false);
    const authRequests = {
        scopes: [process.env.REACT_APP_API_SCOPE] as string[],
        requestUri: process.env.REACT_APP_REDIRECT_URL
    }
    const pca = new PublicClientApplication(msalConfig);
    
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequests}
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}
            >
                <div>
                    <Header></Header>
                    <br></br>
                    <Container>
                        <h1 style={TitleStyle}>DIR Bulk Upload</h1>
                    </Container>
                    <br></br>
                    <Container>
                        <h1 style={HeaderStyle}>The bulk upload runs using an <a href="./DIR-Bulk-Upload-Template.xlsx">MS
                            Excel template file</a> that is converted to XML by the user</h1>
                    </Container>
                    <br></br>
                    <ValidationAndSubmit submittedChanger={setSubmitted} submittedStatus={submitted}/>
                    <br></br>
                    <Container>
                        <FileUploads/>
                        <Row><br></br></Row>
                    </Container>
                    <Container>
                        <p />
                        For any questions or assistance needed, please contact support via a <a href="https://support.principal.com/secure/CreateIssue!default.jspa?pid=15006" target="_blank" rel="noreferrer">JIRA ticket.</a>
                        <p />
                    </Container>
                    <PdsFooter
                        contactLink="https://www.principal.com/contact-us"
                        variant="subtle"
                        layoutContainerVariant = {"default"}
                        removeLayoutContainerPadding = {"all"}
                        socialIcons={[]}
                    />
                </div>
            </MsalAuthenticationTemplate>
        </MsalProvider>

    );
}

export default App;
