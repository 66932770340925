import { PdsButton } from "@principal/design-system-react";

export function ClearScreenButton(props: any) {

    return (
        <div>
            <PdsButton
                 variant="primary"
                disabled={props.disabled}
                onClick={() => {
                    window.location.reload();
                }}>
                CLEAR SCREEN
            </PdsButton>
        </div>
    )
}
