import * as React from 'react';
import { PdsDescriptionListItem as PdsDescriptionListItemElement } from '@principal/design-system';
import { PdsDescriptionListItemProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsDescriptionListItem =
  createPdsReactComponent<PdsDescriptionListItemProps>()({
    tagName: 'pds-description-list-item',
    elementClass: PdsDescriptionListItemElement,
    react: React,
  });
