import {Configuration, LogLevel, RedirectRequest} from '@azure/msal-browser';
export const msalConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    navigateToLoginRequestUrl: true
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false    },
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};

export const msalRequest: RedirectRequest = {
  scopes: [],
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL!,
};

let applicationScopes = [''];
if (process.env.REACT_APP_API_SCOPE) {
  applicationScopes = [process.env.REACT_APP_API_SCOPE];
}

export const protectedResources = {
  api: {
    endpoint: process.env.REACT_APP_API,
    scopes: applicationScopes
  }
}
