import {Col, Container, Row} from "@pds-react/grid";
import {ValidateFilesButton} from "./ValidateButton";
import {ClearScreenButton} from "./ClearScreenButton";
import {SubmitFilesButton} from "./SubmitFilesButton";
import {FeedbackMessage} from "./FeedbackMessage";
import {useState} from "react";

export function ValidationAndSubmit(props: any) {
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessages, setErrorMessages] = useState(['']);
    
    return (
        <div>
            <Container>
                <Row>
                    <Col/>
                    <Col/>
                    <Col/>
                    <Col>
                        <Container>
                            <ClearScreenButton/>
                        </Container>
                    </Col>
                    <Col> 
                        <Container>
                            {!validated ?
                                <ValidateFilesButton validationChanger={setValidated}
                                                     errorMessageChanger={setErrorMessages}/>
                                : <SubmitFilesButton 
                                    submittedChanger={setSubmitted}/>
                            }                            
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Container><br></br></Container>
            <Container>
                <Row>
                {
                    errorMessages[0]==="" && !validated ?
                    <div/>  :
                    <FeedbackMessage errors={errorMessages} validationStatus={validated} submittedStatus={submitted}/>
                }                
                <br></br>
                </Row>
            </Container>
        </div>
    )
}