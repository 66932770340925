import { Col, Row } from "@pds-react/grid";
import FileUpload from "@pds-react/fileUpload";
import { useAtom } from "jotai";
import { pdfs, xmls } from "../atoms/Atoms";
import { FileData } from '../interfaces/FileData';
import { BinaryFileData } from "../interfaces/BinaryFileData";


export function FileUploads() {
    const [, setPdfList] = useAtom(pdfs);
    const [, setXmlList] = useAtom(xmls);

    async function extracted(events: any) {
      const fileList = [...events.target.files];
        let files: FileData[] = [];

        await Promise.allSettled(fileList.map(async (inputFile) => {
            const fileContent = await inputFile.text();
            const fileName = inputFile.name;
            const file = { fileName: fileName, fileData: fileContent };
            files.push(file);
        }));

        return files;
    }
    async function extractBinary(events: any) {
        const fileList = [...events.target.files];
        let files: BinaryFileData[] = [];

        await Promise.allSettled(fileList.map(async (inputFile: File) => {
            const fileContent = await inputFile.arrayBuffer()
            const fileName = inputFile.name;
            const file = { fileName: fileName, fileData: fileContent };
            files.push(file);
        }));

        return files;
    }

    async function getXMLFileName(events: any) {
        let files = await extracted(events);
        setXmlList(files);

        console.log("XML files: " + files.length);
    }

    async function getPDFFileName(events: any) {
        let files = await extractBinary(events);
        setPdfList(files);

        console.log("PDF files: " + files.length);
    }
   
    return (
        <Row>
            <Col>
                <FileUpload
                    onChange={getXMLFileName}
                    accept=".xml"
                    id="xml-file-upload-input"
                    label="Attach the XML file you need to use to process this upload"
                    helperText="You may only attach one XML file"
                    required
                />
            </Col>
            <Col>
                <FileUpload
                    onChange={getPDFFileName}
                    accept=".pdf"
                    id="pdf-file-upload-input"
                    label="Attach the PDF files you need to upload"
                    helperText="You may attach multiple PDF files"
                    multiple
                    required
                />
            </Col>
        </Row>
    );
}
