import { EventName } from '@lit-labs/react';
import { PdsNavDropdownLink as PdsNavDropdownLinkElement } from '@principal/design-system';
import * as React from 'react';
import { PdsNavDropdownLinkProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsNavDropdownLink =
  createPdsReactComponent<PdsNavDropdownLinkProps>()({
    tagName: 'pds-nav-dropdown-link',
    elementClass: PdsNavDropdownLinkElement,
    react: React,
    events: {
      onClick: 'pds-nav-dropdown-link-click' as EventName<CustomEvent>,
    },
  });
