import * as React from 'react';
import { PdsSecondaryNavigationLevelOne as PdsSecondaryNavigationLevelOneElement } from '@principal/design-system';
import { PdsSecondaryNavigationLevelOneProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsSecondaryNavigationLevelOne =
  createPdsReactComponent<PdsSecondaryNavigationLevelOneProps>()({
    tagName: 'pds-secondary-navigation-level-one',
    elementClass: PdsSecondaryNavigationLevelOneElement,
    react: React,
  });
