import * as React from 'react';
import { PdsNavContainer as PdsNavContainerElement } from '@principal/design-system';
import { PdsNavContainerProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsNavContainer = createPdsReactComponent<PdsNavContainerProps>()({
  react: React,
  tagName: 'pds-nav-container',
  elementClass: PdsNavContainerElement,
});
