import {atom} from "jotai";

const object:Object = {}
export const pdfs = atom(object);
export const xmls = atom(object);
export const completed = atom(object);

export enum actionEnum {
    Add = "ADD",
    Update = "UPDATE",
    Revision = "REVISION"
}

