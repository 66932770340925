import { parseString } from 'xml2js';
import { FileData } from '../interfaces/FileData';
import { BinaryFileData } from '../interfaces/BinaryFileData';
import { actionEnum } from "../atoms/Atoms";

let errorList: string[] = [];

export function findValidationErrors(xmlFile: [FileData], pdfList: [BinaryFileData], errorMessageChanger:any): string [] {

    let pdfNameList: string[] = [], requestPdfList: string[] = [];
    errorList = [];

    if (uploadFilesExist(xmlFile, pdfList)) {
        xmlFile.forEach(xml => {
            parseString(xml.fileData.toString(), (err, result) => {
                if (!result) {
                    errorList.push('XML file is empty');
                    return errorList;
                }

                validateRequestDataSize(xml.fileData.toString())

                requestPdfList = verifyRequestData(result["ns1:itemRegistryRequest"]["ns1:itemDetails"]);
            })

            if (pdfList.length !== undefined) {
                pdfList.forEach(function (value) {
                    pdfNameList.push(value.fileName);
                })
            }

            // This list only has requests for ADD and REVISION actions
            // They are the only ones that need a matching PDF
            requestPdfList.forEach(function (requestValue) {            // For each request item, check for matching PDF
                if (pdfNameList.indexOf(requestValue) === -1) {
                    errorList.push('Request change does not having a matching PDF file: ' + requestValue);
                }
            })

            pdfNameList.forEach(function (pdfName) {              //For each PDF, check for matching request item
                if (requestPdfList.indexOf(pdfName) === -1) {
                    errorList.push('PDF file does not having a matching request: ' + pdfName);
                }
            })
        })
    }
    errorMessageChanger(errorList);
    return errorList;
}

export function uploadFilesExist(xmlFile: [FileData], pdfList: [BinaryFileData]) {
      if ((!Object.keys(xmlFile).length) && (!Object.keys(pdfList).length)) {
        errorList.push("No files were provided");
        return false;
    }
    else if (!Object.keys(xmlFile).length) {
        errorList.push("No XML file exists");
        return false;
    }
    else {
        return true;
    }
}

export function validateRequestDataSize(fileData: String) {
    let result: boolean = false;
    let maxBodySizeInBytes = 5619985;

    try {
        parseString(fileData, (err, requestData) => {
            for (let i = 0; i < requestData["ns1:itemRegistryRequest"]["ns1:itemDetails"].length; i++) {
                requestData["ns1:itemRegistryRequest"]["ns1:itemDetails"][i]["ns1:documentName"][0] = encodeURIComponent(requestData["ns1:itemRegistryRequest"]["ns1:itemDetails"][i]["ns1:documentName"][0])
            }

            let bodySize = new TextEncoder().encode(JSON.stringify(requestData)).length;

            console.log("Encoded body size: " + bodySize);
            let kbSize = bodySize / 1024;
            let mbSize = kbSize / 1024;
            console.log("Encoded size in KB: " + kbSize);
            console.log("Encoded size in MB: " + mbSize);

            if (bodySize > maxBodySizeInBytes) {
                let percentOfAllowedBodySize = (Math.round((bodySize/maxBodySizeInBytes) * 10000)/100).toFixed(2);
                errorList.push("Request size too large. Your request is "+ percentOfAllowedBodySize + "% of the size allowed. Please split your request into smaller requests.");
                result  = false;
            } else {
                result = true;
            }
        })

    } catch (error) {
        console.log('Error Processing File: ' + error);
    }
    return result;
}

export function verifyRequestData(requestListData: any) {

    let requestPdfList: string[] = [];

    let action: string,        prefix: string,        baseNumber: string; 
    let suffix: string,        title: string,         revisionNumber: string,   description: string;
    let contentOwner: string,  infoClass: string,     retentionCode: string,    comments: string;
    let itemType: string,      itemSubType: string,   documentName: string,     businessId: string;
    let customerType:string,   tickerSymbol:string,   documentAvailableViaSystems: string;

    let versionEffDate: string,    expirationDate: string;
    let reviewByDate: string,      publicationEffectiveDate: string;

    // Check rules for each input in the request's data
    for (let i = 0; i < requestListData.length; i++) {

        action = String(requestListData[i]["ns1:action"]).toUpperCase().trim();
        prefix = String(requestListData[i]["ns1:businessId"][0]["ns1:prefix"]).trim();
        baseNumber = String(requestListData[i]["ns1:businessId"][0]["ns1:baseNumber"]).trim();
        suffix = String(requestListData[i]["ns1:businessId"][0]["ns1:suffix"]).trim();
        revisionNumber = String(requestListData[i]["ns1:businessId"][0]["ns1:revisionNumber"]).trim();
        title = String(requestListData[i]["ns1:title"]).trim();
        description = String(requestListData[i]["ns1:description"]).trim();
        contentOwner = String(requestListData[i]["ns1:contentOwner"]).trim();
        infoClass = String(requestListData[i]["ns1:informationClassification"]).trim();
        retentionCode = String(requestListData[i]["ns1:retentionCode"]).trim();
        versionEffDate = String(requestListData[i]["ns1:versionEffectiveDate"]).trim();
        expirationDate = String(requestListData[i]["ns1:expirationDate"]).trim();
        reviewByDate = String(requestListData[i]["ns1:reviewByDate"]).trim();
        comments = String(requestListData[i]["ns1:comments"]).trim();
        itemType = String(requestListData[i]["ns1:itemType"]).trim();
        itemSubType = String(requestListData[i]["ns1:itemSubType"]).trim();
        documentName = String(requestListData[i]["ns1:documentName"]).trim();
        documentAvailableViaSystems = String(requestListData[i]["ns1:documentAvailableViaSystems"]).trim();
        publicationEffectiveDate = String(requestListData[i]["ns1:publicationEffectiveDate"]).trim();
        customerType = String(requestListData[i]["ns1:customerType"]).trim();
        tickerSymbol = String(requestListData[i]["ns1:tickerSymbol"]).trim();

        if (!actionIsValid(action)) { continue; }  // Required to pass; exit request
        if (!prefixIsValid(action, prefix)) { continue; }  // Required to pass; exit request
        if (!baseNumberIsValid(action, prefix, baseNumber)) { continue; }   // Required to pass; exit request
        if (!suffixIsValid(suffix)) { continue; }   // Required to pass; exit request
        businessId = prefix + baseNumber + suffix;

        if (documentNameIsValid(businessId, action, documentName)) {
            requestPdfList.push(documentName);
        }

        if (revisionNumberIsValid(action, revisionNumber)) {
            businessId.concat("-", revisionNumber);
        }

        if (!titleIsValid(businessId, title)) { continue; }  // Required; exit request
        if (!itemTypeIsValid(businessId, itemType)) { continue; }   // Required; exit request
        if (!itemSubTypeIsValid(businessId, itemSubType)) { continue; }  // Required; exit request
        if (!contentOwnerIsValid(businessId, contentOwner)) { continue; }   // Required; exit request

        validateDescription(businessId, description);
        validateInformationClassification(businessId, infoClass);
        validateRetentionCode(businessId, retentionCode);
        validateVersionEffectiveDate(businessId, versionEffDate);
        validateExpirationDate(businessId, expirationDate);
        validateReviewByDate(businessId, reviewByDate);
        validateComments(businessId, comments);

        validateDocumentAvailableViaSystems(businessId, documentAvailableViaSystems);
        validatePublicationEffectiveDate(businessId, publicationEffectiveDate);
        validateCustomerType(businessId, customerType);
        validateTickerSymbol(businessId, tickerSymbol);
    }

    return requestPdfList;
}

export function actionIsValid(action: string): boolean {  // ACTION (dropdown) [Required]
    let result: boolean = true;
    if (action !== actionEnum.Add && action !== actionEnum.Update && action !== actionEnum.Revision) {
        errorList.push("action is invalid; on action of " + action + ".  Only actions of ADD, UPDATE, or REVISION are allowed.");
        result = false;
    }
    return result;
}

export function prefixIsValid(action:string, prefix: string): boolean {
    let result: boolean = true;
    if ((prefix === "undefined") || (!prefix.length)) {
        errorList.push(("prefix is a required field that had no value provided; on action of " + action));
        result = false
    }
    return result;
}

export function baseNumberIsValid(action: string, prefix: string, baseNumber: string): boolean {

    let result: boolean = false;
    switch (true) {
        case ((action === actionEnum.Add) && (prefix !== "FS") && (baseNumber.length > 0)): {
                errorList.push("ADD action with prefix not 'FS' should have an empty baseNumber");
                break;
        }
        case ((action === actionEnum.Add) && (prefix === "FS") && (!baseNumber.length)): {
                errorList.push("ADD action with prefix 'FS' should not have an empty baseNumber");
                break;
        }
        case (isNaN(Number(baseNumber))): {
                errorList.push("baseNumber value is not a number >" + baseNumber + "<");
                break;
        }
        case (baseNumber.length > 6): {
                errorList.push("baseNumber value is longer than the six digit limit >" + baseNumber + "<");
                break;
        }
        default: {
            result = true;
        }
    }
    return result;
}

export function suffixIsValid(suffix: string): boolean {

    let result: boolean = false;
    const suffixValueClean = suffix.replace(/[^a-zA-Z0-9]/g, "");

    switch(true) {
        case ((suffix === "undefined") || (!suffix.length)): {
            errorList.push("suffix value is required");
            break;
        }
        case (suffix.length > 10): {
            errorList.push("suffix value is longer than the 10 character limit >" + suffix + "<");
            break;
        }
        case (suffix !== suffixValueClean): {
            errorList.push("suffix has characters that are not allowed >" + suffix + "<");
            break;
        }
        case (!isLetter(suffix.substring(0,1))): {
            errorList.push("suffix begins with a value that is not alphabetic >" + suffix.substring(0,1) + "<");
            break;
        }
        case (suffix !== suffix.toUpperCase()): {
            errorList.push("suffix has letters not in uppercase form >" + suffix + "<");
            break;
        }
        default: {
            result = true;
        }
    }
    return result;
}

export function revisionNumberIsValid(action: string, revisionNumber: string): boolean {
    let result: boolean = false;

    if (action === actionEnum.Update) {
        switch (true) {
            case ((revisionNumber === "undefined") || (!revisionNumber.length)): {
                errorList.push("revisionNumber value required for action UPDATE");
                break;
            }
            case (isNaN(Number(revisionNumber))): {
                errorList.push("revisionNumber value is not a number >" + revisionNumber + "<");
                break;
            }
            case (revisionNumber.length > 4): {
                errorList.push("revisionNumber value is longer than the four digit limit >" + revisionNumber + "<");
                break;
            }
            default: {
                result = true;
            }
        }
    }
    return result;
}

export function titleIsValid(businessId: string, title: string): boolean {
    let result: boolean = false;

    const titleClean = title.replace(/[^a-zA-Z0-9 &-]/g, " ");  // Hyphen, Ampersand, and a Space are allowed

    switch (true) {
       case ((title === "undefined") || (!title.length)): {
            errorList.push(businessId.concat(": title is a required field that had no value provided"));
            break;
        }
       case (title.length > 100): {
            errorList.push(businessId.concat(": title value is longer than the 100 character limit"));
            break;
        }
       case (title !== titleClean): {
            errorList.push("title has characters that are not allowed");
            break;
        }
       default: {
            result = true;
        }
    }
    return result;
}

export function itemTypeIsValid(businessId: string, itemType: string): boolean {
    let result: boolean = true;

    if (( itemType === "undefined") || (!itemType.length)) {
        errorList.push(businessId.concat(": itemType is a required field that had no value provided"));
        result = false;
    }
    return result;
}

export function itemSubTypeIsValid(businessId: string, itemSubType: string): boolean {
    let result: boolean = true;

    if ((itemSubType === "undefined") || (!itemSubType.length)) {
        errorList.push(businessId.concat(": itemSubType is a required field that had no value provided"));
        result = false;
    }
    return result;
}

export function contentOwnerIsValid(businessId: string, contentOwner: string): boolean {
    let result: boolean = true;

    if ((contentOwner === "undefined") || (!contentOwner.length)) {
        errorList.push(businessId.concat(": contentOwner value is not provided"));
        result = false;
    }
    else if (contentOwner.length > 100) {
        errorList.push(businessId.concat(": contentOwner value is longer than the 100 character limit"));
        result = false;
    }
    else if (!isValidEmail(contentOwner)) {
        errorList.push(businessId.concat(": contentOwner value is not in valid email address format"));
        result = false;
    }
    return result;
}

export function validateDescription(businessId: string, description: string): boolean {
    let result: boolean = true;

    if ((description !== "undefined") && (description.length)) {
        if (description.length > 500) {
            errorList.push(businessId.concat(": description value is longer than the 500 character limit"));
            result = false;
        }
    }
    return result;
}
export function validateInformationClassification(businessId: string, informationClassification: string): boolean {
    let result: boolean = true;

    if ((informationClassification !== "undefined") && (informationClassification.length)) {
        if (informationClassification.length > 50) {
            errorList.push(businessId.concat(": informationClassification value is longer than the 50 character limit"));
            result = false;
        }
    }
    return result;
}

export function validateRetentionCode(businessId: string, retentionCode: string): boolean {
    let result: boolean = true;

    if ((retentionCode === "undefined") || (!retentionCode.length)) {
        errorList.push(businessId.concat(": retentionCode value was not provided"));
        result = false;
    }
    return result;
}

export function validateVersionEffectiveDate(businessId: string, versionEffectiveDateValue: string): boolean {
   let result: boolean = true;

    if ((versionEffectiveDateValue !== "undefined") && (versionEffectiveDateValue !== "")) {
        if (!isValidDate(versionEffectiveDateValue)) {
            errorList.push(businessId.concat(": versionEffectiveDate value is an invalid date input"));
            result = false;
        }
        else if (!isValidDateFormat(versionEffectiveDateValue)) {
            errorList.push(businessId.concat(": versionEffectiveDateValue value is an invalid date format. Must be like YYYY-MM-DD."));
            result = false;
        }
    }
    return result;
}

export function validateExpirationDate(businessId: string, expirationDateValue: string): boolean {
    const todayDate = new Date();
    const expirationDate = new Date(expirationDateValue);
    let result: boolean = true;

    if ((expirationDateValue !== "undefined") && (expirationDateValue !== "")) {
        if (!isValidDate(expirationDateValue)) {
            errorList.push(businessId.concat(": expirationDate value is an invalid date input"));
            return false;
        }
        else if (!isValidDateFormat(expirationDateValue)) {
            errorList.push(businessId.concat(": expirationDateValue value is an invalid date format. Must be like YYYY-MM-DD."));
            result = false;
        }
        else if (expirationDate <= todayDate) {     // Expiration date is future only
            errorList.push(businessId.concat(": expirationDate value must be a future date"));
            return false;
        }
    }
    return result;
}

export function validateReviewByDate(businessId: string, reviewByDateValue: string): boolean {
    const todayDate = new Date();
    const reviewByDate = new Date(reviewByDateValue);
    let result: boolean = true;

    if ((reviewByDateValue !== "undefined") && (reviewByDateValue !== "")) {
        if (!isValidDate(reviewByDateValue)) {
            errorList.push(businessId.concat(": reviewByDate value is an invalid date input"));
            result = false;
        }
        else if (!isValidDateFormat(reviewByDateValue)) {
            errorList.push(businessId.concat(": reviewByDateValue value is an invalid date format. Must be like YYYY-MM-DD."));
            result = false;
        }
        else if (reviewByDate <= todayDate) {     // Review by date is future only
            errorList.push(businessId.concat(": reviewByDate value must be a future date"));
            result = false;
        }
    }
    return result;
}

export function validateComments(businessId: string, comments: string): boolean {
    let result: boolean = true;

    if (( comments !== "undefined" ) && (comments.length)) {
        if (comments.length > 1000) {
            errorList.push(businessId.concat(": comments value is longer than the 1000 character limit"));
            result = false;
        }
    }
    return result;
}

export function documentNameIsValid(businessId: string, action: string, documentName: string): boolean {
    let result: boolean = true;

    if (action === actionEnum.Update) {   // Document name is not required
        result = false;
    }
    else {
        if (( documentName === "undefined") || (!documentName.length)) {
            errorList.push(businessId.concat(": documentName value is need for action ADD or REVISION"));
            result = false;
        }
        else if (documentName.length > 50) {
            errorList.push(businessId.concat(": documentName value is longer than the 50 character limit"));
            result = false;
        }
        else if ( /^([a-zA-Z0-9_-]+\.pdf)$/.test(documentName) === false ) {
            errorList.push(businessId.concat(": Cannot upload file with double extenstion. Use only '.pdf' extenstion"));
        }
    }
    return result;
}

export function validateDocumentAvailableViaSystems(businessId: string, documentAvailableViaSystems: string): boolean {
    let result: boolean = true;

    if ((documentAvailableViaSystems !== "undefined") && (documentAvailableViaSystems.length)) {
        if (documentAvailableViaSystems.length > 35) {
            errorList.push(businessId.concat(": documentAvailableViaSystems value is longer than the 35 character limit"));
            result = false;
        }
    }
    return result;
}

export function validatePublicationEffectiveDate(businessId: string, publicationEffectiveDateValue: string): boolean {
    let result: boolean = true;

    if ((publicationEffectiveDateValue !== "undefined") && (publicationEffectiveDateValue !== "")) {
        if (!isValidDate(publicationEffectiveDateValue)) {
            errorList.push(businessId.concat(": publicationEffectiveDate value is an invalid date input"));
            result = false;
        }
        else if (!isValidDateFormat(publicationEffectiveDateValue)) {
            errorList.push(businessId.concat(": publicationEffectiveDate value is an invalid date format. Must be like YYYY-MM-DD."));
            result = false;
        }
    }
    return result;
}

export function validateCustomerType(businessId: string, customerType: string): boolean {
    let result: boolean = true;

    if ((customerType !== "undefined") && (customerType.length)) {
        if (customerType.length > 25) {
            errorList.push(businessId.concat(": customerType value is longer than the 25 character limit"));
            result = false;
        }
    }
    return result;
}

export function validateTickerSymbol(businessId: string, tickerSymbol: string): boolean {
    let result: boolean = true;

    if ((tickerSymbol !== "undefined") && (tickerSymbol.length)) {
        if (tickerSymbol.length > 1000) {
            errorList.push(businessId.concat(": tickerSymbol value is longer than the five character limit"));
            result = false;
        }
    }
    return result;
}

export function isValidDate(value: any): boolean {
    let dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
}

export function isValidDateFormat(value: any): boolean {
    let regEx = /^[0-9]+(-[0-9]+)+$/;
    return regEx.test(value);
}

export function isLetter(value: string): any {
    return value.length === 1 && value.match(/[a-zA-Z]/);
}

export function isValidEmail(value: string): any {
    let regEx = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
    return regEx.test(value);
}
